import background from '../assets/img/authBg.png';
import logo from '../assets/img/apluskids_he.png';
import logoHeader from '../assets/img/logo_header.png';
import logoFooter from '../assets/img/logo_footer.png';
import footerCloudsOrange from '../assets/img/clouds_orange.svg';
import footerCloudsBlue from '../assets/img/clouds_blue.svg';
import loginImage from '../assets/img/login-img.svg';
import registerImage from '../assets/img/register-img.svg';
import overviewImage from '../assets/img/overview-img.svg';
import editImage from '../assets/img/edit-img.svg';
import changePaswdImage from '../assets/img/change-pswd-img.svg';
import linesBgImage from '../assets/img/lines-bg.png';
import starsBgImage from '../assets/img/stars-bg.png';
import circlesBgImage from '../assets/img/circles-bg.png';
import boyImage from '../assets/img/boy-img.svg';
import girlImage from '../assets/img/girl-img.svg';
import boyInCapImage from '../assets/img/boy-in-cap-img.svg';
import crown from '../assets/img/crown.svg';
import shadow from '../assets/img/shadow.svg';

export const LOGIN_BACKGROUND = background;
export const LOGIN_BACKGROUND_COLOR = 'linear-gradient(to bottom right, #F2F4F8, #F2F4F8)';
export const LOGO = logo;
export const LOGO_FOOTER = logoFooter;
export const LOGO_HEADER = logoHeader;
export const CLOUDS_ORANGE = footerCloudsOrange;
export const CLOUDS_BLUE = footerCloudsBlue;

export const LOGIN_IMG = loginImage;
export const REGISTER_IMG = registerImage;
export const OVERVIEW_IMG = overviewImage;
export const EDIT_IMG = editImage;
export const CHANGE_PSWD_IMG = changePaswdImage;
export const LINES_BG_IMG = linesBgImage;
export const STARS_BG_IMG = starsBgImage;
export const CIRCLES_BG_IMG = circlesBgImage;
export const BOY_IMG = boyImage;
export const GIRL_IMG = girlImage;
export const BOY_IN_CAP_IMG = boyInCapImage;
export const CROWN = crown;
export const SHADOW = shadow;

export const appName = 'AplusKids';
