// @flow
import createApiAction from '../../api/createApiAction';
import * as c from './types';

// eslint-disable-next-line import/prefer-default-export
export const getConfig = () =>
  createApiAction({
    method: 'getConfig',
    requestAction: c.GET_CONFIG_REQUEST,
    successAction: c.GET_CONFIG_SUCCESS,
    errorHandler(dispatch, {response}) {
      dispatch({
        type: c.GET_CONFIG_ERROR,
        payload: response.data.error,
      });
    },
    transformResponse: ({data}) => data,
  });
