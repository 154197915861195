import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import Actions from './actions';
import ApiClient from './api';
import ReduxToastr from './utils/redux-toastr';
import Favicon from './components/favicon';
import store from './store';
import api from './api/methods';
import { getCookie, setCookie } from './constants';
serviceWorker.unregister();
const render = () => {
  // eslint-disable-next-line global-require
  const App = require('./app').default;
  // SMOKY BLACK
  // #100B00
  // ST. PATRICK'S BLUE
  // #274C77
  // GLAUCOUS
  // #6279B8
  // BABY BLUE EYES
  // #A3CEF1
  // SHINY SHAMROCK
  // #4C9F70
  const fallbackColor = '#274C77';
  /*     window.document.domain='apluskids.co.il'
   */ const setCssVariable = (name, value) => {
    if (document.documentElement) {
      document.documentElement.style.setProperty(name, value);
    }
  };

  setCssVariable('--primary', fallbackColor);
  setCssVariable('--primary-dark', fallbackColor);
  setCssVariable('--primary-light', fallbackColor);
  /*   Sentry.init({
    dsn: "https://577c360334664f87b5cf61fc94d06e2d@o222553.ingest.sentry.io/4504588334858240",
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  }); */
  ReactDOM.render(
    <Provider store={store}>
      <>
        <App />
        <ReduxToastr />
        <Favicon />
      </>
    </Provider>,
    document.getElementById('root')
  );
};

ApiClient.injectApiMethods(api);

store.dispatch(Actions.getTranslations(render, store));
var url = new URL(window.location.href);
var isIframe = url.searchParams.get('iframe');
if (isIframe) {
  document.getElementById('root').classList.add('is-iframe');
  let cookie = getCookie('user_data');
  if (cookie) {
    setCookie('isIframe', true, 7);
    cookie = typeof cookie == 'string' ? JSON.parse(cookie) : cookie;
    cookie.messageType = 'setCookie';
    window.parent.postMessage(cookie, 'https://www.apluskids.co.il');
  } else {
    window.parent.postMessage({ messageType: 'unSetCookie' }, 'https://www.apluskids.co.il');
  }
} else {
  setCookie('isIframe', true, -1);
}
