// @flow
import type {ReduxStoreType} from '../../types';
import type {ConfigStateType} from './reducers';

export const stateSelector = (state: ReduxStoreType): ConfigStateType => state.config;

export const getConfigData = ({config: {data}}: ReduxStoreType) => data;

export const isEquitySelector = (state: ReduxStoreType): boolean => Boolean(getConfigData(state).isEquity);

export const whitelabelSelector = (state: ReduxStoreType) => getConfigData(state).whitelabel;
export const whitelabelNameSelector = (state: ReduxStoreType): string =>
  whitelabelSelector(state) ? whitelabelSelector(state).name : '';
export const primaryColorSelector = (state: ReduxStoreType): string => {
  const config = stateSelector(state);
  return config.data.primaryColor || '';
};
