import React from 'react';
import {DialogConsumer, DialogProvider} from '../utils/context/dialogContext';

const withDialog = Component => props => (
  <DialogProvider>
    <DialogConsumer>
      {({showDialog, hideDialog}) => <Component {...props} showDialog={showDialog} hideDialog={hideDialog}/>}
    </DialogConsumer>
  </DialogProvider>
);

export default withDialog;
