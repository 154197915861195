import {toastr} from 'react-redux-toastr';

const toastrOptions = {
  position: 'top-right',
}
export default {
  success: (title, message) => {
    toastr.success(title, message, toastrOptions)
  },
  error: (title, message) => {
    toastr.error(title, message, toastrOptions)
  }
};
