// @flow
import React, {Component} from 'react';
import {Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, UncontrolledDropdown} from 'reactstrap';
import {LogOut, Menu, User} from 'react-feather';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {omit} from 'lodash';
import {Translate} from 'react-i18nify';
import PropTypes from 'prop-types';

import userImage from '../../assets/img/sample-avatar.jpg';
import LogoIcon from "../commons/logo-icon";

import {FlagsSelect } from '../index';

import {setTranslationLocale} from '../../ducks/i18n/actions';
import {getTranslationOptions} from '../../ducks/i18n/selectors';

import {userDuck} from '../../ducks';
// $FlowFixMe

class ThemeNavbar extends Component {
  static defaultProps = {
    exclude: [],
  };

  get links() {
    const {exclude, logout} = this.props;

    const links = {
      profile: (
        <Link to="/profile" className="p-0" key={1}>
          <DropdownItem>
            <User size={16} className="ml-1"/>
            <Translate value="my_profile_label"/>
          </DropdownItem>
        </Link>
      ),
      logout: (
        <DropdownItem onClick={logout} aria-label="logout" key={2}>
          <LogOut size={16} className="ml-1"/>
          <Translate value="logout_btn"/>
        </DropdownItem>
      ),
    };

    return Object.values(exclude.length > 0 ? omit(links, exclude) : links);
  }

  componentDidMount() {
    const {user} = this.props;
    if (Object.entries(user).length !== 0) {
    }
  }

  componentDidUpdate(prevProps) {
    const {user} = this.props;
    if (Object.entries(user).length !== Object.entries(prevProps.user).length) {
    }
  }

  handleClick = () => {
    const {toggleSidebarMenu} = this.props;
    toggleSidebarMenu('open');
  };

  render() {
    const {user, options, setTranslationLocale} = this.props;
    return (
              <Nav className="" navbar>
       
                <li>
                  <Link to="/subscription">
                    <Translate value="upgrade_link"/>
                  </Link>
                </li>

               
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    <Translate value="profile_link" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem disabled>{user.email}</DropdownItem>
                    <DropdownItem divider/>
                    {this.links}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
  /*     <Navbar className="navbar navbar-expand-lg bg-faded header">
        <div className="container-fluid px-0">
          <div className="header-logo">
            <LogoIcon />
          </div>

          <div className="navbar-container header-nav">
            <Collapse isOpen navbar>
            </Collapse>
          </div>
        </div>
      </Navbar> */
    );
  }
}

ThemeNavbar.propTypes = {
  logout: PropTypes.func,
  setTranslationLocale: PropTypes.func,
  toggleSidebarMenu: PropTypes.func,
  user: PropTypes.object,
  exclude: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  color: PropTypes.string,
};

const mapStateToProps = state => {
  const isLoading = userDuck.selectors.loadingSelector(state);
  return {
    options: getTranslationOptions(state),
    userData: userDuck.selectors.stateSelector(state),
    isLoading,
    color: '#00000',
  };
};

const mapDispatchToProps = {
  setTranslationLocale,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThemeNavbar);
