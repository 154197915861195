import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {I18n} from 'react-i18nify';
import '../assets/scss/components/multiple-select/multiple-select.scss';

const MultipleSelect = ({initialValues, options, disabled, ...rest}) => (
  <Select
    defaultValue={initialValues}
    isMulti
    name="currency"
    options={options}
    isDisabled={disabled}
    className={classnames('basic-multi-select', {invalid: rest.invalid})}
    classNamePrefix="select"
    placeholder={I18n.t('select_placeholder')}
    {...rest}
  />
);

MultipleSelect.propTypes = {
  disabled: PropTypes.bool,
  initialValues: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MultipleSelect.defaultProps = {
  disabled: false,
  initialValues: [],
};

export default MultipleSelect;
