// @flow
import reducer from './reducers';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';

export default {
  actions,
  reducer,
  selectors,
  types,
};
