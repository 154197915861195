// @flow
import {createActionsCurried} from '../../utils/actions';
import {appName} from '../../constants/config';

const moduleName = 'auth';
const prefix = `${appName}/${moduleName}`;
const buildActions = createActionsCurried(prefix);

export const [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR] = buildActions('LOGIN');
export const [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_ERROR] = buildActions('LOGOUT');
export const [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_ERROR] = buildActions('REGISTER');
export const [FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR] = buildActions(
  'FORGOT_PASSWORD'
);
export const [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_ERROR] = buildActions('RESET_PASSWORD');
