/* eslint-disable no-await-in-loop */
import {compose, withHandlers} from 'recompose';
import {formatFileName} from '../utils';

import api from '../api';
import {I18n} from '../utils/i18n';
import toastr from '../utils/toastr';

export default compose(
  withHandlers({
    /**
     * This method handles the uploading of files to s3.
     * @param  {{ key: string, value: File }} files
     * @returns {Object}
     */
    uploadFiles: () => async files => {
      try {
        const {data} = await api.request('POST', '/storage/requestUpload', {
          filesToUpload: {
            ...Object.keys(files).reduce((acc, val) => ({...acc, [val]: formatFileName(files[val].name)}), {}),
          },
        });

        for (const file in data) {
          if (data[file]) {
            try {
              await fetch(data[file].presignedUrl, {
                method: 'PUT',
                body: files[file],
              });
            } catch (err) {
              toastr.error(I18n.t('system_popup'), I18n.t('failed_Upload_File', {file: data[file].fileName}));
            }
          }
        }

        return data;
      } catch (err) {
        toastr.error(I18n.t('system_popup'), I18n.t('try_to_Upload_again'));

        return {};
      }
    },
  })
);
