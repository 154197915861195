// @flow
import createReducer from '../../reducers/utils/createReducer';
import * as c from './types';
import type {ErrorType} from '../../types';

export type UserStateType = $ReadOnly<{
  isLoading: boolean,
  error: ErrorType,
  data: {
    id: number,
    profile?: {
      identityType: string,
    },
  },
}>;

export const initialState: UserStateType = {
  isLoading: false,
  error: null,
  data: {},
};

export default createReducer(initialState, {
  [c.GET_USER_PROFILE_REQUEST]: (state: UserStateType): UserStateType => ({
    ...state,
    data: {},
    error: null,
    isLoading: true,
  }),
  [c.GET_USER_PROFILE_SUCCESS]: (state: UserStateType, {payload}): UserStateType => ({
    ...state,
    isLoading: false,
    data: payload,
  }),
  [c.GET_USER_PROFILE_ERROR]: (state: UserStateType, {payload}): UserStateType => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  // Create or Update Custom
  [c.SAVE_USER_PROFILE_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.SAVE_USER_PROFILE_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    isLoading: false,
  }),
  [c.SAVE_USER_PROFILE_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
