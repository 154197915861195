// @flow
import * as actions from './actions';
import reducer from './reducers';
import * as selectors from './selectors';
import * as types from './types';

export default {
  actions,
  reducer,
  selectors,
  types,
};
