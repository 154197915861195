import { GET_USER_PROFILE_SUCCESS } from '../user/types';
import * as c from './types';
import { I18n } from '../../utils/i18n';
import toastr from '../../utils/toastr';
import createApiAction from '../../api/createApiAction';
import { getUserProfile } from '../user/actions';
import { getCookie, setCookie } from '../../constants';
import { Redirect } from 'react-router-dom';

import { wpSite } from '../../config';
// eslint-disable-next-line import/prefer-default-export
export const getUserInvoices = () =>
  createApiAction({
    method: 'getUserInvoices',
    requestAction: c.GET_USER_INVOICES_REQUEST,
    successAction: c.GET_USER_INVOICES_SUCCESS,
    errorHandler(dispatch, { response }) {
      const errorMessage = response.data.error;
      dispatch({
        type: c.GET_USER_INVOICES_ERROR,
        payload: errorMessage,
      });
    },
    transformResponse: ({ data }) => data,
  });

export const getUserInvoice = id =>
  createApiAction({
    method: 'getUserInvoice',
    requestAction: c.GET_USER_INVOICE_REQUEST,
    successAction: c.GET_USER_INVOICE_SUCCESS,
    params: {
      id,
    },
    errorHandler(dispatch, { response }) {
      const errorMessage = response.data.error;
      dispatch({
        type: c.GET_USER_INVOICE_ERROR,
        payload: errorMessage,
      });
    },
    transformResponse: ({ data }) => data,
  });
export const cancelSubscription = subId =>
  createApiAction({
    method: 'cancelSubscription',
    requestAction: c.CANCEL_SUBSCRIPTION_REQUEST,
    successAction: c.CANCEL_SUBSCRIPTION_SUCCESS,
    params: { subId },
    errorHandler(dispatch, { response }) {
      //toastr.error(I18n.t('system_popup'), I18n.t('an_error_has_occured'));
      const errorMessage = response ? response.data.error : '';
      // console.log(errorMessage);
      dispatch({
        type: c.CANCEL_SUBSCRIPTION_ERROR,
        payload: errorMessage,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: (dispatch, { data }) => {
      toastr.success(I18n.t('system_popup'), I18n.t('subscription_canceled_success'));
      // dispatch(getUserProfile());
      window.parent.postMessage({ messageType: 'cancelSubscription' }, 'https://www.apluskids.co.il');

      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: data,
      });
    },
  });
export const cancelOldSubscription = userId =>
  createApiAction({
    method: 'cancelOldSubscription',
    requestAction: c.CANCEL_SUBSCRIPTION_REQUEST,
    successAction: c.CANCEL_SUBSCRIPTION_SUCCESS,
    params: { userId },
    errorHandler(dispatch, { response }) {
      //toastr.error(I18n.t('system_popup'), I18n.t('an_error_has_occured'));
      const errorMessage = response ? response.data.error : '';
      // console.log(errorMessage);
      dispatch({
        type: c.CANCEL_SUBSCRIPTION_ERROR,
        payload: errorMessage,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: (dispatch, { data }) => {
      toastr.success(I18n.t('system_popup'), I18n.t('subscription_canceled_success'));
      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: data,
      });
    },
  });
export const cancelOldSubscriptionRequest = (userId, data) =>
  createApiAction({
    method: 'cancelOldSubscriptionRequest',
    requestAction: c.CANCEL_SUBSCRIPTION_REQUEST,
    successAction: c.CANCEL_SUBSCRIPTION_SUCCESS,
    params: { userId, data },
    errorHandler(dispatch, { response }) {
      console.log('error');
      //toastr.error(I18n.t('system_popup'), I18n.t('an_error_has_occured'));
      const errorMessage = response ? response.data.error : '';
      // console.log(errorMessage);
      dispatch({
        type: c.CANCEL_SUBSCRIPTION_ERROR,
        payload: errorMessage,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: (dispatch, { data }) => {
      toastr.success(I18n.t('system_popup'), I18n.t('request_sent'));
      /* setTimeout(() => {
        const url=new URL(window.location.href)
        console.log('search');
        console.log(url.searchParams.get('iframe'));
        if (url.searchParams.get('iframe')) {

          window.location.replace(`${window.location.origin}/subscription?iframe=true`);
        }else{
          window.location.replace(`${window.location.origin}/subscription`);

        }
      }, 1000); */
      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: data,
      });
    },
  });

export const getAvailableSubscriptions = () =>
  createApiAction({
    method: 'getAvailableSubscriptions',
    requestAction: c.GET_AVAILABLE_PRODUCTS_REQUEST,
    successAction: c.GET_AVAILABLE_PRODUCTS_SUCCESS,
    errorHandler(dispatch, { response }) {
      const errorMessage = response.data.error;
      dispatch({
        type: c.GET_AVAILABLE_PRODUCTS_ERROR,
        payload: errorMessage,
      });
    },
    transformResponse: ({ data }) => data,
  });

export const submitSubscriptionPayment = dataToSend =>
  createApiAction({
    method: 'submitSubscriptionPayment',
    requestAction: c.SUBMIT_SUBSCRIPTION_PAYMENT_REQUEST,
    successAction: c.SUBMIT_SUBSCRIPTION_PAYMENT_SUCCESS,
    params: dataToSend,
    errorHandler(dispatch, { response }) {
      if (response) {
        toastr.error(I18n.t('system_popup'), I18n.t(response.data.error));

        dispatch({
          type: c.SUBMIT_SUBSCRIPTION_PAYMENT_ERROR,
          payload: response.data.error,
        });
      } else {
        dispatch({
          type: c.SUBMIT_SUBSCRIPTION_PAYMENT_ERROR,
          payload: 'Unknown',
        });
      }
    },
    transformResponse: ({ data }) => data,
    afterSuccess: async (dispatch, { data }) => {
      
      toastr.success(I18n.t('system_popup'), I18n.t('subscription_changed_success'));
      if (getCookie('isIframe')) {
        window.parent.postMessage({ messageType: 'subscription', data }, 'https://www.apluskids.co.il');
      }
      let cookie = JSON.parse(getCookie('user_data'));
      if (!cookie.has_subscription) {
        console.log(data.user.currentSubscription.has_subscription);
        cookie.has_subscription = data.user.currentSubscription.has_subscription;
        setCookie('user_data', JSON.stringify(cookie), 7);
      }
      if (!getCookie('isIframe')) {
        setTimeout(() => {
          console.log(getCookie('user_data'));
          window.location.replace(wpSite);
          return;
        }, 500);
      }
      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: data.user,
      });
    },
  });
