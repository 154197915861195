import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {css, cx} from "emotion";
import {Col, Row, Container} from "reactstrap";

import {userDuck} from '../ducks';
import Header from '../components/header';
import Footer from '../components/footer';
import LogoIcon from "../components/commons/logo-icon";
import {LOGIN_BACKGROUND, LOGIN_BACKGROUND_COLOR} from "../constants/config";

class AuthLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
    userData: PropTypes.shape({
      profile: PropTypes.objectOf(PropTypes.any),
      role: PropTypes.string,
    }),
  };

  static defaultProps = {
    userData: {
      profile: {},
      role: '',
    },
  };

  state = {
    width: window.innerWidth,
    sidebarState: 'close',
    sidebarSize: '',
    layout: '',
  };

  get alertColor() {
    return 'primary';
  }

  get alertText() {
    return '';
  }

  componentDidMount() {
    if (window !== 'undefined') {
      window.addEventListener('resize', this.updateWidth, false);
    }
  }

  componentWillUnmount() {
    if (window !== 'undefined') {
      window.removeEventListener('resize', this.updateWidth, false);
    }
  }

  updateWidth = () => {
    this.setState({width: window.innerWidth});
  };

  handleSidebarSize = sidebarSize => {
    this.setState({sidebarSize});
  };

  handleLayout = layout => {
    this.setState({layout});
  };

  toggleSidebarMenu = sidebarState => {
    this.setState({sidebarState});
  };

  render() {
    const {children} = this.props;
    const style = css`
            
            background-size: contain;
            &:before {
              background: ${LOGIN_BACKGROUND_COLOR};
            }
          `;
    return (
        <div className={cx('auth-background', style)}>
         {/*  <Header /> */}
          <Container className={'auth-wrapper'}>
            <Row>
              <Col xs={12} className="d-flex align-items-center justify-content-center">
                <div className="width-450">
                  {/* <div className="auth-logo">
                    <LogoIcon/>
                  </div> */}
                </div>
              </Col>
            </Row>

            <main className="position-relative">{children}</main>
          </Container>
          <Footer {...this.props} />
        </div>
    );
  }
}

const mapStateToProps = state => ({
  userData: userDuck.selectors.userDataSelector(state),
});

export default connect(
  mapStateToProps,
  {}
)(AuthLayout);
