import createApiAction from '../../api/createApiAction';
import * as c from './types';
import {getUserProfile} from '../user/actions';
import {syncTranslationWithStore} from '../../utils/i18n';

export const getTranslations = (cb, store) =>
  createApiAction({
    method: 'getTranslations',
    requestAction: c.GET_TRANSLATIONS_REQUEST,
    successAction: c.GET_TRANSLATIONS_SUCCESS,
    errorHandler(dispatch, error) {
      dispatch({
        type: c.GET_TRANSLATIONS_ERROR,
        payload: error,
      });
      dispatch(
        getUserProfile({
          onSuccess: cb,
          onError: cb,
        })
      );
    },
    transformResponse: ({data}) => data,
    afterSuccess: dispatch => {
      syncTranslationWithStore(store);
      dispatch(
        getUserProfile({
          onSuccess: cb,
          onError: cb,
        })
      );
    },
  });

export const setTranslationLocale = payload => ({
  type: c.SET_TRANSLATION_LOCALE,
  payload,
});
