export default {
  login(data) {
    return {
      method: 'POST',
      url: '/auth/login',
      data,
    };
  },
  loginFb(data) {
    return {
      method: 'POST',
      url: '/auth/register-facebook',
      data,
    };
  },
  logout() {
    return {
      method: 'GET',
      url: '/auth/logout',
    };
  },
  register(data) {
    return {
      method: 'POST',
      url: '/auth/register',
      data,
    };
  },
  getUserProfile() {
    return {
      method: 'GET',
      url: '/auth/me',
    };
  },
  saveUserProfile(data) {
    return {
      method: 'POST',
      url: '/auth/save-profile',
      data,
    };
  },
  changeProfilePassword(data) {
    return {
      method: 'POST',
      url: '/auth/change-password',
      data,
    };
  },
  forgotPassword(data) {
    return {
      method: 'POST',
      url: '/auth/forgotPassword',
      data,
    };
  },
  resetPassword(data) {
    return {
      method: 'POST',
      url: '/auth/resetPassword',
      data,
    };
  },
  getNotifications() {
    return {
      method: 'GET',
      url: '/notification/me',
    };
  },
  dismissNotification(id) {
    return {
      method: 'PATCH',
      url: `/notification/dismiss/${id}`,
    };
  },
  readNotifications(ids) {
    return {
      method: 'PATCH',
      url: '/notification/read/bulk',
      data: {
        ids,
      },
    };
  },
  getTranslations() {
    return {
      method: 'GET',
      url: 'translation/all',
    };
  },
  saveAllTranslations(data) {
    return {
      method: 'POST',
      url: 'translation/save/all',
      data,
    };
  },
  updateTranslation(data) {
    return {
      method: 'POST',
      url: 'translation/lang/update',
      data,
    };
  },
  removeTranslation(lang) {
    return {
      method: 'DELETE',
      url: `translation/${lang}`,
    };
  },
  updateTranslationByKey({ lang, ...data }) {
    return {
      method: 'PATCH',
      url: `translation/${lang}`,
      data,
    };
  },
  getUserInvoices() {
    return {
      method: 'GET',
      url: 'billing/invoices',
    };
  },
  getAvailableSubscriptions() {
    return {
      method: 'GET',
      url: 'billing/subscriptions',
    };
  },
  submitSubscriptionPayment(data) {
    return {
      method: 'POST',
      url: '/billing/submit-subcription-payment',
      data,
    };
  },
  getUserInvoice({ id }) {
    return {
      method: 'GET',
      url: `billing/invoice/${id}`,
    };
  },
  cancelSubscription(subId) {
    return {
      method: 'POST',
      url: `billing/cancel`,
      data: subId,
    };
  },
  cancelOldSubscription(userId) {
    return {
      method: 'POST',
      url: `billing/cancelOldSubscription`,
      data: userId,
    };
  },
  cancelOldSubscriptionRequest(userId, params) {
    return {
      method: 'POST',
      url: `billing/cancelOldSubscriptionRequest`,
      data: { userId, params },
    };
  },
};
