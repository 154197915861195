import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {AlertTriangle, CheckCircle, Info, XCircle} from 'react-feather';

import '../assets/scss/components/dialog/dialog.scss';

const DIALOG_ICONS = {
  success: <CheckCircle className="dialog-icon" size={60}/>,
  danger: <XCircle className="dialog-icon" size={60}/>,
  error: <XCircle className="dialog-icon" size={60}/>,
  warning: <AlertTriangle className="dialog-icon" size={60}/>,
  info: <Info className="dialog-icon" size={60}/>,
};

const Dialog = ({type, title, renderBody, ...rest}) => (
  <Modal className="pulsar-dialog shadow" {...rest}>
    <ModalHeader className={classnames('d-flex justify-content-center', type)}>{DIALOG_ICONS[type]}</ModalHeader>
    <ModalBody className="text-center">
      <h3>{title}</h3>
      {renderBody()}
    </ModalBody>
  </Modal>
);

Dialog.defaultProps = {
  type: '',
  title: '',
  renderBody: () => null,
};

Dialog.propTypes = {
  type: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  renderBody: PropTypes.func,
};

export default Dialog;
