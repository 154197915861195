import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import auth from '../ducks/auth/reducers';
import user from '../ducks/user/reducers';
// import notifications from '../ducks/notifications/reducers';
import i18n from '../ducks/i18n/reducers';
import billing from '../ducks/billing/reducers';
import { getCookie } from '../constants';

const appReducer = combineReducers({
  auth,
  user,
  // notifications,
  billing,
  i18n,
  toastr: toastrReducer,
});

const rootReducer = (state, action) => {
  const isIframe = getCookie('isIframe');
  if (action.type === 'LOGOUT_REQUEST') {
    // Reset the whole app state when user logouts
    return appReducer({ i18n: state.i18n, config: state.config }, action);
  }
  if (action.type != 'AplusKids/auth/LOGOUT_SUCCESS' || (action.type == 'AplusKids/auth/LOGOUT_SUCCESS' && !isIframe)) {
    return appReducer(state, action);
  }
};

export default rootReducer;
