import {compose, withHandlers} from 'recompose';
import {withAuth} from '../hocs';
import ForgotPasswordPage from '../pages/forgot-password-page';

export default compose(
  withAuth,
  withHandlers({
    onForgotClick: ({forgotPassword}) => ({email, redirectUrl}) =>
      forgotPassword({email, redirectUrl, resetParameter: 'resetCode'}),
  })
)(ForgotPasswordPage);
