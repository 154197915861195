import React, {Component} from 'react';
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n} from 'react-i18nify';
import history from '../utils/history';
import getActivePage from '../utils/activePage';

class PaginationComponent extends Component {
  state = {
    activePage: 1,
    firstPaginationNumber: 1,
  };

  constructor(props) {
    super(props);
    this.pages = this.getNumberOfPages();
  }

  componentDidMount() {
    const {onSelect} = this.props;
    const {location} = history;
    const current = getActivePage(location);
    onSelect(current);
    if (current !== this.pages) {
      this.setState({activePage: current || 1, firstPaginationNumber: Math.max(1, current - 1)});
    } else {
      this.setState({activePage: current || 1, firstPaginationNumber: Math.max(1, current - 2)});
    }
  }

  getNumberOfPages = () => {
    const {totalItems, pageSize} = this.props;
    const auxPages = totalItems / pageSize;
    let pages = parseInt(auxPages, 10);
    pages += pages !== auxPages ? 1 : 0;

    return pages;
  };

  paginationItems = () => {
    const {firstPaginationNumber} = this.state;
    const items = [];

    this.lastPaginationNumber = this.getLastPaginationNumber();
    items.push(this.firstOrLastPagItem(I18n.t('firstpage_btn'), 1));
    items.push(this.nextOrPreviousPagItem(I18n.t('previous_btn'), 1, 'l'));
    // eslint-disable-next-line no-plusplus
    for (let i = firstPaginationNumber; i <= this.lastPaginationNumber; i++) {
      items.push(this.numberedPageItem(i));
    }

    if (this.lastPaginationNumber < this.pages) {
      items.push(
        <PaginationItem key="dots" disabled>
          <PaginationLink style={{minWidth: '43.5px'}}> ... </PaginationLink>
        </PaginationItem>
      );
      items.push(this.numberedPageItem(this.pages));
    }
    items.push(this.nextOrPreviousPagItem(I18n.t('next_btn'), this.pages, 'r'));

    return items;
  };

  getLastPaginationNumber = () => {
    const {firstPaginationNumber} = this.state;
    const {maxPaginationNumbers} = this.props;
    const minNumberPages = Math.min(this.pages, maxPaginationNumbers);

    return firstPaginationNumber + minNumberPages - 1;
  };

  numberedPageItem = i => {
    const {activePage} = this.state;

    return (
      <PaginationItem key={i} id={`pagebutton${i}`} active={activePage === i} onClick={this.handleClick}>
        <PaginationLink style={{minWidth: '43.5px'}}>{i}</PaginationLink>
      </PaginationItem>
    );
  };

  nextOrPreviousPagItem = (name, page, direction) => {
    const {activePage} = this.state;

    return (
      <PaginationItem
        key={name}
        disabled={activePage === page}
        onClick={() => this.handleSelectNextOrPrevious(direction)}
      >
        <PaginationLink>{name}</PaginationLink>
      </PaginationItem>
    );
  };

  firstOrLastPagItem = (name, page) => {
    const {activePage} = this.state;
    const event = {
      currentTarget: {
        getAttribute: () => `pagebutton${page}`,
      },
    };

    return (
      <PaginationItem key={name} disabled={activePage === page} onClick={() => this.handleClick(event)}>
        <PaginationLink>{name}</PaginationLink>
      </PaginationItem>
    );
  };

  handleClick = event => {
    const {onSelect} = this.props;
    const newActivePage = parseInt(
      event.currentTarget
        .getAttribute('id')
        .split('pagebutton')
        .pop(),
      10
    );

    this.setState({activePage: newActivePage});
    this.handlePaginationNumber(newActivePage);
    history.push({search: `?page=${newActivePage}`});
    onSelect(newActivePage);
  };

  handleSelectNextOrPrevious = direction => {
    const {activePage} = this.state;
    const {onSelect} = this.props;

    if ((direction === 'r' && activePage === this.pages) || (direction === 'l' && activePage === 1)) {
      return;
    }

    const newActivePage = direction === 'r' ? activePage + 1 : activePage - 1;
    this.setState({activePage: newActivePage});
    this.handlePaginationNumber(newActivePage);

    history.push({search: `?page=${newActivePage}`});
    onSelect(newActivePage);
  };

  handlePaginationNumber = activePage => {
    const {firstPaginationNumber} = this.state;
    const {maxPaginationNumbers} = this.props;
    const distance = Math.floor(maxPaginationNumbers / 2);
    const newFPNumber = activePage - distance;
    const newLPNumber = activePage + distance;

    if (newFPNumber <= 1) {
      if (firstPaginationNumber !== 1) {
        this.setState({firstPaginationNumber: 1});
      }
    } else if (newLPNumber <= this.pages) {
      this.setState({firstPaginationNumber: newFPNumber});
    } else if (newLPNumber >= this.pages) {
      this.setState({firstPaginationNumber: this.pages - maxPaginationNumbers + 1});
    }
  };

  render() {
    const {className} = this.props;
    return <Pagination className={className}>{this.paginationItems()}</Pagination>;
  }
}

PaginationComponent.propTypes = {
  totalItems: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
  maxPaginationNumbers: PropTypes.number,
  className: PropTypes.string,
};

PaginationComponent.defaultProps = {
  maxPaginationNumbers: 5,
  className: '',
  onSelect() {
  },
};

const mapStateToProps = ({i18n}) => ({
  selected: {
    country: i18n.locale,
  },
});

export default connect(mapStateToProps)(PaginationComponent);
