import { divide } from 'lodash';
import React from 'react';
import {LOGO_HEADER} from '../../constants/config';


const HeaderLogo = () => (
  <div className="header-logo-wrap">
    <img alt="logo" src={LOGO_HEADER} />
    <br/>
    <b>לגדול חכם | A+ Kids</b>
  </div>
);

export default HeaderLogo;
