import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router-dom';
import {withAuth} from '../hocs';
import {FeatureFlagComponent} from '../components';

import MainLayout from '../layouts/main-layout';

const MainLayoutRoute = ({render, auth: {isAuthenticated}, flag, showOnlyWhenDisabled, ...rest}) => {
  const route = (
    <Route
      {...rest}
      render={matchProps =>
        isAuthenticated ? <MainLayout>{render(matchProps)}</MainLayout> : <Redirect to="/login"/>
      }
    />
  );
  return flag ? (
    <FeatureFlagComponent name={flag} showOnlyWhenDisabled={showOnlyWhenDisabled}>
      {route}
    </FeatureFlagComponent>
  ) : (
    route
  );
};

MainLayoutRoute.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
  }).isRequired,
  render: PropTypes.func.isRequired,
  flag: PropTypes.string,
  showOnlyWhenDisabled: PropTypes.bool,
};

MainLayoutRoute.defaultProps = {
  flag: undefined,
  showOnlyWhenDisabled: false,
};

export default withAuth(MainLayoutRoute);
