import React from 'react';
import PropTypes from 'prop-types';

import {NavLink} from 'react-router-dom';

const SidebarItem = ({path, mayBeDisabled, isApproved, wip, name, access}) => {
  const disable = (mayBeDisabled && !isApproved) || wip;
  if (access) {

    return (
      <NavLink
        to={path}
        activeclassname="active"
        style={{color: `${disable ? 'rgba(0,0,0,0.5)' : ''}`}}
        onClick={e => {
          if (disable) {
            e.preventDefault();
          }
        }}
        disabled={disable}
      >
        <span className="menu-item-text text-capitalize">{name}</span>
      </NavLink>
    );
  } else {

    return '';
  }
};

SidebarItem.propTypes = {
  path: PropTypes.string.isRequired,
  mayBeDisabled: PropTypes.bool,
  isApproved: PropTypes.bool,
  wip: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  icon: PropTypes.element,
  access: PropTypes.bool,
};

SidebarItem.defaultProps = {
  mayBeDisabled: false,
  isApproved: false,
  wip: false,
  access: true
};

export default SidebarItem;
