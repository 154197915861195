import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getTranslationOptions} from "../ducks/i18n/selectors";
import {setTranslationLocale} from "../ducks/i18n/actions";
import AuthLayout from '../layouts/auth-layout';

const AuthOverlay = ({children}) => {

  return <AuthLayout>{children}</AuthLayout>;
};

AuthOverlay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const mapStateToProps = state => ({
  options: getTranslationOptions(state),
});

const mapDispatchToProps = {
  setTranslationLocale,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthOverlay);

