// eslint-disable-next-line import/prefer-default-export
export const MAX_FILE_SIZE = 1024 * 1024 * 5;
export const LS_TOKEN_KEY = '__jwtAppToken__';
export const setCookie = (cname, cvalue, exdays)=>{
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + "; "/* +"domain=.apluskids.co.il" */;
}
export const getCookie = (cname)=>{
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const NOTIFICATION_TYPES = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
};
export const NOTIFICATION_DISMISSION_STATUSES = {
  READ: 'read',
  DISMISS: 'dismiss',
};
export const defaultLang = 'he';

export {BASE_URL} from '../api';
