// @flow
type HandlersType = {
  [string]: Function,
};

type ActionType = {
  type: string,
  payload?: mixed,
};

const createReducer = (initialState: {}, handlers: HandlersType) => (
  state: typeof initialState = initialState,
  action: ActionType
) => (action.type in handlers ? handlers[action.type](state, action) : state);

export default createReducer;
