import { compose, withHandlers } from 'recompose';
import LoginPage from '../pages/login-page';
import { withAuth } from '../hocs';
export default compose(
  withAuth,
  withHandlers({
    onLoginClick: ({ login }) => async ({ email, password }) => {
      const res = await login({ email, password });
    },
  })
)(LoginPage);
