import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Translate} from '../../utils/i18n';
import SidebarItem from './sidebar-item';
import SidebarMenu from './sidebar-menu';
import ReactGA from "react-ga";
import publicIp from "public-ip";

import {History} from '../../utils';

const envApp = process.env.REACT_APP_ENV;

if (envApp === 'master') {
  History.listen(location => {
    ReactGA.set({page: location.pathname});
    ReactGA.pageview(location.pathname);
  });
}

class SidebarMenuContent extends Component {

  componentDidMount() {
    if (envApp === 'master') {
      // this.initializeReactGA();
    }
  }

  initializeReactGA() {
    ReactGA.initialize('UA-156284518-4');
    publicIp.v4().then((ipAddress) => {
      ReactGA.event({
        category: 'User',
        action: 'Login',
        label: `ID: ${this.props.userData.id} | IP: ${ipAddress}`
      });
    });
  }

  render() {

    const data = [
      {
        name: <Translate value="dashboard_sidebar"/>,
        path: '/dashboard',
      },
      {
        name: <Translate value="profile_sidebar"/>,
        path: '/profile',
      },
      {
        name: <Translate value="change_password_sidebar"/>,
        path: '/change-password',
      },
      {
        name: <Translate value="subscription_sidebar"/>,
        path: '/subscription',
      },
      {
        name: <Translate value="billing_sidebar"/>,
        path: '/billing',
      }
    ];

    const isApproved = this.props.userData && this.props.userData.profile;
    return (
      <SidebarMenu className="sidebar-content" toggleSidebarMenu={this.props.toggleSidebarMenu}>
        {data.map(el => (
          <SidebarMenu.MenuSingleItem key={el.path}>
            <SidebarItem isApproved={isApproved} {...el} />
          </SidebarMenu.MenuSingleItem>
        ))}
      </SidebarMenu>
    );
  }
}

SidebarMenuContent.propTypes = {
  toggleSidebarMenu: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    profile: PropTypes.shape(PropTypes.any),
  }).isRequired,
};

export default SidebarMenuContent;
