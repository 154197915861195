import React from 'react';
import Routes from './routes';
import Helmet from 'react-helmet';



const Index = () => (
    <Routes />
);

export default Index;
