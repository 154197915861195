import React from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import {Info} from 'react-feather';
import {isEmpty} from 'lodash';
import '../assets/scss/components/tooltip/tooltip.scss';

const Tooltip = ({position, title, description, showIcon, showDelay, hideDelay, style, children, ...props}) => (
  <Popup
    trigger={children}
    position={position}
    on="hover"
    mouseEnterDelay={showDelay}
    mouseLeaveDelay={hideDelay}
    contentStyle={{
      width: 'max-content',
      maxWidth: '100%',
      borderRadius: '4px',
      borderColor: 'rgb(244, 244, 244)',
      padding: '8px 9px',
      ...style,
    }}
    arrowStyle={{
      boxShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 1px',
    }}
    {...props}
  >
    <>
      <p className="tooltip-title">
        {showIcon && <Info size={13} style={{margin: '0px 0.375rem 0px 1px'}}/>}
        {title}
      </p>
      {description && <p className="tooltip-description">{description}</p>}
    </>
  </Popup>
);

export const TooltipWrapper = ({tooltipProps, children}) =>
  !isEmpty(tooltipProps) ? <Tooltip {...tooltipProps}>{children}</Tooltip> : [children];

TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipProps: PropTypes.objectOf(PropTypes.any),
};

TooltipWrapper.defaultProps = {
  tooltipProps: {},
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipProps: PropTypes.shape(PropTypes.any),
  position: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.string,
  showIcon: PropTypes.bool,
  showDelay: PropTypes.number,
  hideDelay: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.any),
};

Tooltip.defaultProps = {
  position: 'top left',
  description: '',
  showIcon: true,
  showDelay: 1000,
  hideDelay: 0,
  style: {},
  title: '',
  tooltipProps: null,
};

export default Tooltip;
