import React, {lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {ForgotPasswordContainer, LoginContainer, RegisterContainer, ResetPasswordContainer} from '../containers';

import {SpinnerComponent} from '../components';

import {withAuth} from '../hocs';

import {History} from '../utils';

import {Translate} from '../utils/i18n';

import {userDuck} from '../ducks';

import MainLayoutRoute from './main-layout-route';
import PrintLayoutRoute from './print-layout-route';

const LazyDashboard = lazy(() => import('../containers/dashboard'));
const LazyProfile = lazy(() => import('../containers/profile'));
const LazyChangePassword = lazy(() => import('../containers/change-password'));
const LazyCancelRequest = lazy(() => import('../containers/cancel-request'));
const LazySubscription = lazy(() => import('../containers/subscription'));
const LazyBilling = lazy(() => import('../containers/billing'));
const LazyReciept = lazy(() => import('../containers/reciept'));
const LazyInvoice = lazy(() => import('../containers/invoice'));
// const LazyInvoice = lazy(() => import('../containers/invoice'));
// const LazyInvoice = lazy(() => import('../containers/invoice'));

const Routes = ({auth, userData}) => (
  <Router basename="/client-dashboard" history={History}>
    <Switch>
      <Route path='/terms' component={() => { 
          window.location.href = 'https://apluskidstv.com/terms_and_conditions'; 
          return null;
      }}/>
      <Route path='/contact' component={() => { 
          window.location.href = 'https://ludikids.com/'; 
          return null;
      }}/>
      <Route path='/privacy-terms' component={() => { 
          window.location.href = 'https://apluskidstv.com/privacy_policy/'; 
          return null;
      }}/>
      <Route
        path="/login"
        render={() => (auth.isAuthenticated && window.location == window.parent.location ? <Redirect to="/dashboard"/> : <LoginContainer/>)}
      />
      <Route
        path="/register"
        render={() =>
          auth.isAuthenticated ? (
            <Redirect to="/subscription"/>
          ) : (
            <RegisterContainer title={<Translate value="company_title"/>} identityType="user"/>
          )
        }
      />
      <Route
        path="/forgot-password"
        render={() => (auth.isAuthenticated ? <Redirect to="/dashboard"/> : <ForgotPasswordContainer/>)}
      />
      <Route
        path="/reset-password"
        render={() => (auth.isAuthenticated ? <Redirect to="/dashboard"/> : <ResetPasswordContainer/>)}
      />
      <MainLayoutRoute
        path="/dashboard"
        render={matchprops => (
          <Suspense fallback={<SpinnerComponent/>}>
            <LazyDashboard {...matchprops} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        path="/profile"
        render={matchprops => (
          <Suspense fallback={<SpinnerComponent/>}>
            <LazyProfile {...matchprops} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        path="/change-password"
        render={matchprops => (
          <Suspense fallback={<SpinnerComponent/>}>
            <LazyChangePassword {...matchprops} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        path="/cancel-request"
        render={matchprops => (
          <Suspense fallback={<SpinnerComponent/>}>
            <LazyCancelRequest {...matchprops} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        path="/subscription"
        render={matchprops => (
          <Suspense fallback={<SpinnerComponent/>}>
            <LazySubscription {...matchprops} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        path="/billing"
        render={matchprops => (
          <Suspense fallback={<SpinnerComponent/>}>
            <LazyBilling {...matchprops} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        path="/reciept/:id"
        render={matchprops => (
          <Suspense fallback={<SpinnerComponent/>}>
            <LazyReciept {...matchprops} />
          </Suspense>
        )}
      />
      <MainLayoutRoute
        path="/invoice/:id"
        render={matchprops => (
          <Suspense fallback={<SpinnerComponent/>}>
            <LazyInvoice {...matchprops} />
          </Suspense>
        )}
      />
      <PrintLayoutRoute
        path="/invoice-print/:id"
        render={matchprops => (
          <Suspense fallback={<SpinnerComponent/>}>
            <LazyInvoice {...matchprops} />
          </Suspense>
        )}
      />
      <PrintLayoutRoute
        path="/reciept-print/:id"
        render={matchprops => (
          <Suspense fallback={<SpinnerComponent/>}>
            <LazyReciept {...matchprops} />
          </Suspense>
        )}
      />
      <Route render={() => <Redirect to={auth.isAuthenticated ? '/dashboard' : '/login'}/>}/>
    </Switch>
  </Router>
);

Routes.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
  }).isRequired,
  userData: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = state => ({
  userData: userDuck.selectors.userDataSelector(state),
});

export default compose(
  connect(mapStateToProps),
  withAuth
)(Routes);
