import React, {PureComponent} from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FoldedContentConsumer, FoldedContentProvider} from '../utils/context/toggleContentContext';
import templateConfig from '../templateConfig';
import Reciept from '../containers/reciept'

import {userDuck} from '../ducks';

import {Container} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';

class PrintLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
    userData: PropTypes.shape({
      profile: PropTypes.objectOf(PropTypes.any),
      role: PropTypes.string,
    }),
  };

  static defaultProps = {
    userData: {
      profile: {},
      role: '',
    },
  };

  state = {
    width: window.innerWidth,
    sidebarState: 'close',
    sidebarSize: '',
    layout: '',
  };

  get alertColor() {
    return 'primary';
  }

  get alertText() {
    return '';
  }

  get scrollbarOptions() {
    return {
      suppressScrollX: true
    }
  }

  componentDidMount() {
    if (window !== 'undefined') {
      window.addEventListener('resize', this.updateWidth, false);
    }
  }

  componentWillUnmount() {
    if (window !== 'undefined') {
      window.removeEventListener('resize', this.updateWidth, false);
    }
  }

  updateWidth = () => {
    this.setState({width: window.innerWidth});
  };

  handleSidebarSize = sidebarSize => {
    this.setState({sidebarSize});
  };

  handleLayout = layout => {
    this.setState({layout});
  };

  toggleSidebarMenu = sidebarState => {
    this.setState({sidebarState});
  };

  render() {
    const {children} = this.props;
    const {sidebarSize, sidebarState, layout} = this.state;

    return (
        <Container className={'main-wrapper main-wrapper_print'}>
                
        <div className="card-wrapper card-wrapper_print">

            <main className="position-relative">{children}</main>

        </div>

      </Container>
    );
  }
}

const mapStateToProps = state => ({
  userData: userDuck.selectors.userDataSelector(state),
});

export default connect(
  mapStateToProps,
  {}
)(PrintLayout);
