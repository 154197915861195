import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import templateConfig from '../../templateConfig';
import {FoldedContentConsumer} from '../../utils/context/toggleContentContext';
import '../../assets/scss/components/sidebar/sidebar.scss';
// import SidebarHeader from './sidebar-header';
import SidebarMenu from './sidebar-menu-data';

class Sidebar extends Component {
  state = {
    collapsedSidebar: templateConfig.sidebar.collapsed,
    width: window.innerWidth,
  };

  get sidebarStyle() {
    const {imgurl} = this.props;
    if (!templateConfig.sidebar.backgroundImage && imgurl) {
      return {
        backgroundImage: `url('${imgurl}')`,
      };
    }
    if (templateConfig.sidebar.backgroundImage && templateConfig.sidebar.backgroundImageURL) {
      return `url('${templateConfig.sidebar.backgroundImageURL}')`;
    }
    return {};
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.updateWidth, false);
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.updateWidth, false);
    }
  }

  updateWidth = () => {
    this.setState(() => ({
      width: window.innerWidth,
    }));
  };

  handleMouseEnter = () => {
    this.setState({
      collapsedSidebar: false,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      collapsedSidebar: true,
    });
  };

  handleCollapsedSidebar = collapsedSidebar => {
    this.setState({
      collapsedSidebar,
    });
  };

  render() {
    const {userData, color, sidebarState} = this.props;
    const {collapsedSidebar, width} = this.state;
    return (
      <Fragment>
        <FoldedContentConsumer>
          {context => (
            <div
              className={classnames(
                'app-sidebar',
                {
                  '': !collapsedSidebar,
                  collapsed: collapsedSidebar,
                },
                {
                  '': sidebarState === 'open',
                }
              )}
              onMouseEnter={context.foldedContent ? this.handleMouseEnter : null}
              onMouseLeave={context.foldedContent ? this.handleMouseLeave : null}
            >
              {/* <SidebarHeader {...this.props} sidebarBgColor={color}/> */}

              <PerfectScrollbar className="sidebar-content">
                <SidebarMenu {...this.props} userData={userData} collapsedSidebar/>
              </PerfectScrollbar>
              <div className="sidebar-background" style={this.sidebarStyle}/>
            </div>
          )}
        </FoldedContentConsumer>
      </Fragment>
    );
  }
}

export default Sidebar;

Sidebar.defaultProps = {
  color: '',
  imgurl: '',
};

Sidebar.propTypes = {
  color: PropTypes.string,
  imgurl: PropTypes.string,
  sidebarState: PropTypes.string.isRequired,
  toggleSidebarMenu: PropTypes.func.isRequired,
  userData: PropTypes.objectOf(PropTypes.any).isRequired,
};
