import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormFeedback, FormGroup, Input, Label} from 'reactstrap';
// import classnames from 'classnames';
import {Info} from 'react-feather';
import {css, cx} from 'emotion';
import {I18n} from 'react-i18nify';
import MultipleSelect from '../multiple-select';
import {Tooltip} from '..';

class FormField extends Component {
  renderFieldByType = type => {
    const {
      name,
      label,
      values,
      errors,
      touched,
      handleChange,
      setFieldValue,
      setValues,
      children,
      disabled,
      showTime,
      tooltip,
      ...rest
    } = this.props;

    if (type === 'select' && rest.options) {
      const orderedOptions = {};
      Object.keys(rest.options)
        .sort()
        .forEach(key => {
          orderedOptions[key] = rest.options[key];
        });

      return (
        <>
          <Input
            name={name}
            id={name}
            onChange={handleChange}
            value={values[name] || ''}
            invalid={!!(errors[name] && touched[name])}
            disabled={disabled}
            {...rest}
            type="select"
          >
            <option value="">{I18n.t('selectSector_label')}</option>
            {Object.entries(orderedOptions).map(([key]) => (
              <option key={key} value={key}>
                {I18n.t(`${key}_option`)}
              </option>
            ))}
          </Input>
          {errors[name] && touched[name] && <FormFeedback className="position-absolute">{errors[name]}</FormFeedback>}
        </>
      );
    }

    if (type === 'multiple-select') {
      return (
        <>
          <MultipleSelect
            name={name}
            id={name}
            initialValues={values[name]}
            options={rest.options}
            invalid={!!(errors[name] && touched[name] && !touched[name].length)}
            onChange={e => setFieldValue(name, e)}
            disabled={disabled}
            {...rest}
          />
          {!!(errors[name] && touched[name] && !touched[name].length) && (
            <FormFeedback className="position-absolute d-block">{errors[name]}</FormFeedback>
          )}
        </>
      );
    }

    if (type === 'number') {
      return (
        <>
          <Input
            name={name}
            id={name}
            value={values[name] || ''}
            invalid={!!(errors[name] && touched[name])}
            disabled={disabled}
            {...rest}
            type="text"
          >
            {children}
          </Input>
          {errors[name] && touched[name] && <FormFeedback className="position-absolute">{errors[name]}</FormFeedback>}
        </>
      );
    }

    return (
      <>
        <Input
          name={name}
          id={name}
          onChange={handleChange}
          value={values[name] || ''}
          invalid={!!(errors[name] && touched[name])}
          disabled={disabled}
          {...rest}
        >
          {children}
        </Input>
        {errors[name] && touched[name] && <FormFeedback className="position-absolute">{errors[name]}</FormFeedback>}
      </>
    );
  };

  render() {
    const {type, name, label, tooltip} = this.props;
    return (
      <FormGroup>
        {label && (
          <Label for={name}>
            {label}
            {tooltip && (
              <Tooltip {...{showIcon: false, showDelay: 0, ...tooltip}}>
                <Info
                  size={13}
                  className={cx(
                    'ml-1',
                    css`
                      transform: scale(0.9);
                      margin-bottom: 2px;
                    `
                  )}
                />
              </Tooltip>
            )}
          </Label>
        )}
        {this.renderFieldByType(type)}
      </FormGroup>
    );
  }
}

FormField.defaultProps = {
  type: 'text',
  label: '',
  children: null,
  disabled: false,
  errors: {},
  touched: {},
  tooltip: null,
  setFieldValue() {
  },
  showTime: false,
  setValues() {
  },
};

FormField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.any),
  touched: PropTypes.objectOf(PropTypes.any),
  handleChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  setFieldValue: PropTypes.func,
  showTime: PropTypes.bool,
  setValues: PropTypes.func,
  tooltip: PropTypes.objectOf(PropTypes.any),
};

export default FormField;
