import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';
import {connect} from 'react-redux';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {I18n} from 'react-i18nify';

class FlagsSelect extends Component {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    selected: this.props.selected,
    isOpen: false,
  };

  handleDropdownToggle = () => {
    const {isOpen} = this.state;

    this.setState({
      isOpen: !isOpen,
    });
  };

  handleItemSelection = item => {
    const {onSelect} = this.props;

    this.setState({selected: item}, () => {
      I18n.setLocale(item.country);
      localStorage.setItem('i18n-locale', item.country);
      onSelect(item);
    });
  };

  render() {
    const {isOpen, selected} = this.state;
    const {options, size} = this.props;

    return (
      <Dropdown nav inNavbar isOpen={isOpen} toggle={this.handleDropdownToggle}>
        <DropdownToggle nav caret>
          <ReactCountryFlag
            code={selected.country}
            styleProps={{
              width: `${size}px`,
              height: `${size}px`,
            }}
            name={selected.country}
            alt={selected.label}
            svg
          />
        </DropdownToggle>
        <DropdownMenu right>
          {options.map(({country, label}) => (
            <DropdownItem
              key={country}
              className="d-flex align-items-center"
              onClick={() => this.handleItemSelection({country, label})}
            >
              <ReactCountryFlag
                code={country}
                name={country}
                alt={label}
                styleProps={{
                  width: `${size}px`,
                  height: `${size}px`,
                  marginRight: '10px',
                }}
                svg
              />
              <span>{label}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

FlagsSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string,
      label: PropTypes.string,
    }).isRequired
  ),
  selected: PropTypes.shape({
    country: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func,
  size: PropTypes.number,
};

FlagsSelect.defaultProps = {
  options: [{}],
  onSelect: () => {
  },
  size: 20,
};

const mapStateToProps = ({i18n}) => ({
  selected: {
    country: i18n.locale,
  },
});

export default connect(mapStateToProps)(FlagsSelect);
