import React from 'react';
import ReduxToastr from 'react-redux-toastr';

const Toastr = () => (
  <ReduxToastr
    timeOut={4000}
    newestOnTop={false}
    preventDuplicates
    position="top-right"
    transitionIn="bounceIn"
    transitionOut="bounceOut"
    progressBar
    closeOnToastrClick
  />
);

export default Toastr;
