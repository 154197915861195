import React from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import favicon from '../../assets/img/favicon.ico';

const Favicon = () => <Helmet link={[{rel: 'icon', type: 'image/png', href: favicon}]}/>;

Favicon.propTypes = {};

Favicon.defaultProps = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Favicon);
