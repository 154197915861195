import {compose, withHandlers} from 'recompose';
import {withAuth} from '../hocs';
import RegisterPage from '../pages/register-page';

export default compose(
  withAuth,
  withHandlers({
    onRegisterClick: ({register}) => ({first_name, last_name, email, password}) =>
      register({first_name, last_name, email, password}),
  })
)(RegisterPage);
