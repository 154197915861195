import React from 'react';
import { connect } from 'react-redux';

class ReceiptPopup extends React.Component {
  render() {
    const {
      receipt
    } = this.props;
    return (
      <>
      </>
    );
  }
}

const mapStateToProps = state => ({
  
});

export default connect(
  mapStateToProps
)(ReceiptPopup);