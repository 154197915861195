import {I18n, Translate} from 'react-i18nify';
import {translationsEn} from './locales/us.json';
import {translationsHe} from './locales/he.json';
import {defaultLang} from '../../constants';
const translations = defaultLang == 'he' ? translationsHe : translationsEn;
const equityIdentifier = 'equity:';
const getEquityTranslation = data =>
  Object.entries(data)
    .filter(([elKey]) => elKey.includes(equityIdentifier))
    .reduce(
      (accum, [elKey, elVal]) => ({
        ...accum,
        [elKey.substring(equityIdentifier.length)]: elVal,
      }),
      {}
    );

export const syncTranslationWithStore = store => {
  I18n.setHandleMissingTranslation(key => {
    /* global.console.warn(
      store.getState().i18n.locale,
      `Missing translation: ${key}.`,
      `Using ${translations[key] ? 'English FallbackMessage' : 'key'}`
    ); */
    return translations[key] || key;
  });
  const initialTranslation = store.getState().i18n.translations;
  const {isEquity} = store.getState().config && store.getState().config.data || {};
  if (isEquity) {
    const updatedtranslation = Object.entries(initialTranslation).reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key]: {...val, ...getEquityTranslation(val)},
      }),
      {}
    );

    I18n.setTranslations(updatedtranslation);
  } else {
    I18n.setTranslations(initialTranslation);
  }
  I18n.setLocaleGetter(() => store.getState().i18n.locale || '');
};

export {I18n, Translate};
