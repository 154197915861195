// @flow
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {
  Card,
  CardBody,
  Col,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import {I18n, Translate} from 'react-i18nify';
import {connect} from 'react-redux';
import LoadingButton from '../components/loading-button';
import AuthOverlay from '../components/auth-overlay';
// import FlagsSelect from '../components/flags-select';
import {getTranslationOptions} from '../ducks/i18n/selectors';
import {setTranslationLocale} from '../ducks/i18n/actions';
import {loginFb} from '../ducks/auth/actions';
import FacebookLogin from "react-facebook-login";
import loginAnimationImg from '../assets/img/lottie/login.json'
import HeaderLogo from '../components/commons/header-logo';
const formSchema = Yup.object().shape({
  password: Yup.string().required(<Translate value="fieldIsRequired_message"/>),
  email: Yup.string()
    .email(<Translate value="invalidEmail_message"/>)
    .required(<Translate value="fieldIsRequired_message"/>),
});

type LoginPropsType = {
  auth: {
    isLoading: boolean,
  },
  allowRegister?: number,
  onLoginClick: number,
  setTranslationLocale: Function,
  options: any[],
};

type LoginStateType = {
  isChecked: boolean,
};

class Login extends Component<LoginPropsType, LoginStateType> {
  static defaultProps = {
    allowRegister: true,
  };

  state = {
    isChecked: false,
  };

  handleToggleCheckbox = () => {
    const {isChecked} = this.state;
    this.setState({isChecked: !isChecked});
  };

  responseFacebook = (response) => {
    const {loginFb} = this.props;
    loginFb(response);
  }

  render() {
    const {isChecked} = this.state;
    const {
      onLoginClick,
      allowRegister,
      auth: {isLoading},
    } = this.props;

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: loginAnimationImg,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <AuthOverlay>
        <Row>
          <Col xs="12" className="d-flex align-items-center justify-content-center">
            <Card className="auth-box card_login">
              <CardBody className="px-0 py-0">
              <HeaderLogo />

                {/* <div className="login-lang">
                    <FlagsSelect options={options} onSelect={({ country }) => setTranslationLocale(country)} />
                  </div> */}

                <h4 className="auth-header">
                  <Translate value="login_title"/>
                </h4>
                {allowRegister && (
                  <div className="auth-sub-header">
                    <span className="pre-link">
                      <Translate value="dontHaveAnAccount_question"/>
                    </span>
                    <Link to="/register">
                      <Translate value="dontHaveAnAccount_link"/>
                    </Link>
                  </div>
                )}
                <Formik
                  initialValues={{email: '', password: ''}}
                  validationSchema={formSchema}
                  onSubmit={onLoginClick}
                >
                  {({values, errors, touched, handleChange, handleSubmit, dirty, isValid}) => (
                    <Form
                      onSubmit={handleSubmit}
                      className="form"
                    >
                      <FormGroup>
                        <Label for="email" className="form-label">
                          <Translate value="email_label"/>
                        </Label>
                        <Input
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder={I18n.t('email_placeholder')}
                          onChange={handleChange}
                          value={values.email}
                          invalid={!!(errors.email && touched.email)}
                        />
                        <FormFeedback className="position-absolute">
                          {errors.email && touched.email && errors.email}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="password" className="form-label">
                          <Translate value="password_label"/>
                        </Label>
                        {/* <Link style={{float: 'right'}} to="/forgot-password">
                          <Translate value="forgotPassword_link"/>
                        </Link> */}
                        <Input
                          type="password"
                          className="form-control"
                          name="password"
                          id="password"
                          placeholder={I18n.t('password_placeholder')}
                          invalid={!!(errors.password && touched.password)}
                          onChange={handleChange}
                          value={values.password}
                        />
                        <FormFeedback className="position-absolute">
                          {errors.password && touched.password && errors.password}
                        </FormFeedback>
                      </FormGroup>
                      <div className="auth-buttons">
                        <Row>
                          <Col
                            xs="12"
                            md="7"
                          >
                            <CustomInput
                              type="checkbox"
                              name="remember"
                              id="remember"
                              checked={isChecked}
                              onChange={this.handleToggleCheckbox}
                              label={I18n.t('rememberMe_label')}
                              inline
                            />
                          </Col>
                          <Col
                            xs="12"
                            md="5"
                          >
                            <Link
                              style={{float: 'right'}}
                              to="/forgot-password"
                              className="forgot-link"
                            >
                              <Translate value="forgotPassword_link"/>
                            </Link>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <LoadingButton isLoading={isLoading} type="submit" color="orange" size="xl"
                                           className="mb-0 btn-md" >
                              <Translate value="login_btn"/>
                            </LoadingButton>
                          </Col>
                        </Row>
                        <div className="card-terms">
                          <Translate value="register_agreement"/>
                          <Link to="/terms" target="blank">
                            <Translate value="terms_service_link"/>
                          </Link>
                        </div>
                        <Row hidden={true}>
                          <Col xs="12">
                            <FacebookLogin
                              appId="298532127827172"
                              autoLoad={false}
                              fields="name,email,picture"
                              callback={this.responseFacebook}
                              cssClass="my-facebook-button-class btn btn-md btn-xl"
                              icon="fa-facebook"
                              render={renderProps => (
                                <button onClick={renderProps.onClick}>This is my custom FB button</button>
                              )}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  )}
                </Formik>
              </CardBody>
              
            </Card>
          </Col>
        </Row>
      </AuthOverlay>
    );
  }
}

const mapStateToProps = state => ({
  options: getTranslationOptions(state),
});

const mapDispatchToProps = {
  setTranslationLocale,
  loginFb,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
