import React from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {withRouter} from 'react-router-dom';
import * as Yup from 'yup';
import {Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';
import {I18n, Translate} from 'react-i18nify';
import {compose, lifecycle} from 'recompose';
import AuthOverlay from '../components/auth-overlay';

import LoadingButton from '../components/loading-button';

const formSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, <Translate value="passwordMustHaveAtLeast6Characters_message"/>)
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d_@.!\/#&+-]{6,}$/, 'סיסמה חייבת להיות עם ספרות ואותיות באנגלית')
    .required(<Translate value="fieldIsRequired_message"/>),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], <Translate value="passwordDontMatch_message"/>)
    .min(6, <Translate value="passwordMustHaveAtLeast6Characters_message"/>)
    .required(<Translate value="fieldIsRequired_message"/>),
});

const ResetPassword = ({auth: {isLoading}, onResetClick}) => (
  <AuthOverlay>
    <Row>
      <Col xs="12" className="d-flex align-items-center justify-content-center">
        <Card className="auth-box width-450">
          <CardBody className="px-0 py-0">
            <h4 className="auth-header">
              <Translate value="resetPassword_page"/>
            </h4>
            <Formik
              initialValues={{
                password: '',
                passwordConfirm: '',
              }}
              validationSchema={formSchema}
              onSubmit={values => {
                const redirectUrl = `${window.location.origin}/dashboard`;
                const urlParams = new URLSearchParams(window.location.search);
                const token = urlParams.get('resetCode');
                onResetClick({...values, redirectUrl, token});
              }}
            >
              {({values, errors, touched, handleChange, handleSubmit, dirty, isValid}) => (
                <Form className="pt-2" onSubmit={handleSubmit}>
                  <FormGroup>
                    <FormGroup>
                      <Label for="password">
                        <Translate value="password_label"/>
                      </Label>
                      <Input
                        type="password"
                        className="form-control"
                        name="password"
                        id="password"
                        placeholder={I18n.t('password_placeholder')}
                        onChange={handleChange}
                        value={values.password}
                        invalid={!!(errors.password && touched.password)}
                      />
                      <FormFeedback className="position-absolute">
                        {errors.password && touched.password && errors.password}
                      </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label for="passwordConfirm">
                        <Translate value="confirmPassword_label"/>
                      </Label>
                      <Input
                        type="password"
                        className="form-control"
                        name="passwordConfirm"
                        id="passwordConfirm"
                        placeholder={I18n.t('password_placeholder')}
                        onChange={handleChange}
                        value={values.passwordConfirm}
                        invalid={!!(errors.passwordConfirm && touched.passwordConfirm)}
                      />
                      <FormFeedback className="position-absolute">
                        {errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm}
                      </FormFeedback>
                    </FormGroup>
                  </FormGroup>
                  <div className="auth-buttons d-flex align-items-center justify-content-between">
                    <LoadingButton isLoading={isLoading} type="submit" color="orange" size="xl"  disabled={!dirty || !isValid}>
                      <Translate value="reset_password_btn"/>
                    </LoadingButton>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </AuthOverlay>
);

ResetPassword.propTypes = {
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  onResetClick: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  lifecycle({
    componentDidMount() {
      if (!this.props.location.search) {
        this.props.history.push('/');
      }
    },
  })
)(ResetPassword);
