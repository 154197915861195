import {compose, withHandlers} from 'recompose';
import {withAuth} from '../hocs';
import ResetPasswordPage from '../pages/reset-password-page';

export default compose(
  withAuth,
  withHandlers({
    onResetClick: ({resetPassword}) => ({password, passwordConfirm: password_confirmation, token}) => resetPassword({password, password_confirmation, token}),
  })
)(ResetPasswordPage);
