import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import {Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from 'reactstrap';

import {I18n, Translate} from 'react-i18nify';
import * as Yup from 'yup';
import AuthOverlay from '../components/auth-overlay';
import LoadingButton from '../components/loading-button';

const formSchema = Yup.object().shape({
  email: Yup.string()
    .email(<Translate value="invalidEmail_message"/>)
    .required(<Translate value="fieldIsRequired_message"/>)
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, <Translate value="invalidEmail_message"/>),
});

const ForgotPassword = ({auth: {isLoading}, onForgotClick}) => (
  <AuthOverlay>
    <Row>
      <Col xs="12" className="d-flex align-items-center justify-content-center">
        <Card className="auth-box card_forgot width-375">
          <CardBody className="px-0 py-0">
            <h4 className="auth-header">
              <Translate value="forgotPassword_title"/>
            </h4>
            <div className="auth-sub-header">
                    <span className="pre-link">
                      <Translate value="dontHaveAnAccount_question"/>
                    </span>
              <Link to="/register">
                <Translate value="dontHaveAnAccount_link"/>
              </Link>
            </div>
            <p><Translate value="forgot_password_text"/></p>
            <Formik
              initialValues={{email: ''}}
              validationSchema={formSchema}
              onSubmit={values => {
                const redirectUrl = `${window.location.origin}/reset-password`;
                onForgotClick({...values, redirectUrl});
              }}
            >
              {({values, errors, touched, handleChange, handleSubmit, dirty, isValid}) => (
                <Form className="pt-2" onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="email" className="form-label">
                      <Translate value="email_label"/>
                    </Label>
                    <Input
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder={I18n.t('email_placeholder')}
                      onChange={handleChange}
                      value={values.email}
                      invalid={!!(errors.email && touched.email)}
                    />
                    <FormFeedback className="position-absolute">
                      {errors.email && touched.email && errors.email}
                    </FormFeedback>
                  </FormGroup>
                  <div className="auth-buttons d-flex align-items-center justify-content-between">
                    <LoadingButton isLoading={isLoading} type="submit" color="orange" size="xl"
                                   className="mb-0 btn-md" >
                      <Translate value="resetPassword_page"/>
                    </LoadingButton>
                  </div>
                  <div className="auth-form-footer">
                    <Link to="/login">
                      <Translate value="back_link"/>
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </AuthOverlay>
);

ForgotPassword.propTypes = {
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  onForgotClick: PropTypes.func.isRequired,
};

export default ForgotPassword;
