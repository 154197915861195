// @flow
import type {UserStateType} from './reducers';
import type {ReduxStoreType} from '../../types';

export const stateSelector = (state: ReduxStoreType): UserStateType => state.user;
export const userDataSelector = (state: ReduxStoreType) => stateSelector(state).data;
export const userIdSelector = (state: ReduxStoreType) => userDataSelector(state).id;
export const loadingSelector = (state: ReduxStoreType): boolean => stateSelector(state).isLoading;
export const identityTypeSelector = (state: ReduxStoreType): string => {
  const {profile = {}} = userDataSelector(state);
  return profile.identityType || '';
};
