import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import classnames from 'classnames';

import '../assets/scss/components/loading-button/loading-button.scss';

const LoadingButton = ({isLoading, disabled, children, spinnerStyles, ...rest}) => (
  <Button {...rest} disabled={isLoading || disabled}>
    <div className={classnames('button-content', {'is-loading': isLoading})}>{children}</div>
    {isLoading && <div className={classnames('spinner', spinnerStyles)} role="status"/>}
  </Button>
);

LoadingButton.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  spinnerStyles: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

LoadingButton.defaultProps = {
  isLoading: false,
  disabled: false,
  spinnerStyles: '',
  children: null,
};

export default LoadingButton;
