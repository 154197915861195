import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Button,
} from 'reactstrap';

import { connect } from 'react-redux';
import { withDialog } from '../../hocs';
import { Link } from 'react-router-dom';
import { Translate } from '../../utils/i18n';
class Dashboard extends React.Component {
  render() {
    const { userData } = this.props;
    let planName = '';
    if (userData.currentSubscription && userData.currentSubscription.product && !userData.currentSubscription.pending_cancellation) {
      planName = userData.currentSubscription.product.name;
    }
    
    return (
      <>
        <Card className="card_main">
          <CardBody>
            <CardTitle tag="h1">
              <Translate value="dashboard_sidebar" />
            </CardTitle>
          </CardBody>
          <CardBody>
            <Row>
              <Col xs="12" md="12" xl="12">
                <Row>
                  <Col xs="12" md="6" xl="6">
                    <CardSubtitle tag="h4"></CardSubtitle>
                    <ListGroup className="no-borders">
                      <ListGroupItem>
                        <ListGroupItemHeading>
                          <Translate value="first_name_label" />
                        </ListGroupItemHeading>
                        <ListGroupItemText>{userData.first_name}</ListGroupItemText>
                      </ListGroupItem>
                      <ListGroupItem>
                        <ListGroupItemHeading>
                          <Translate value="last_name_label" />
                        </ListGroupItemHeading>
                        <ListGroupItemText>{userData.last_name}</ListGroupItemText>
                      </ListGroupItem>
                    </ListGroup>
                    {/* <Link to='/profile'>
                      <Button color="blue" size="xl" className="btn-full">
                        <Translate value="profile_sidebar"/>
                      </Button>
                    </Link> */}
                  </Col>
                  <Col xs="12" md="6" xl="6">
                    <CardSubtitle tag="h4"></CardSubtitle>
                    <ListGroup className="no-borders">
                      <ListGroupItem>
                        <ListGroupItemHeading>
                          <Translate value="plan_name_label" />
                        </ListGroupItemHeading>
                        <ListGroupItemText>
                          <Translate
                            value={planName}
                          />
                        </ListGroupItemText>
                      </ListGroupItem>
                    </ListGroup>
                    {/* <Link to='/subscription'>
                      <Button color="orange" size="xl" className="btn-full">
                        <Translate value="subscription_sidebar"/>
                      </Button>
                    </Link> */}
                  </Col>
                  <Col xs="12">
                    <ListGroup>
                      <ListGroupItem>
                        <ListGroupItemHeading>
                          <Translate value="email_label" />
                        </ListGroupItemHeading>
                        <ListGroupItemText>{userData.email}</ListGroupItemText>
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="6" xl="6">
                <Link to="/profile">
                  <Button color="blue" className="btn-full regular-button">
                    <Translate value="profile_sidebar" />
                  </Button>
                </Link>
              </Col>
              <Col xs="12" md="6" xl="6">
                <Link to="/subscription">
                  <Button color="orange" className="btn-full regular-button">
                    <Translate value="subscription_sidebar" />
                  </Button>
                </Link>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.user && state.user.data ? state.user.data : {},
});
export default connect(mapStateToProps)(withDialog(Dashboard));
