import React from 'react';
import {Container} from "reactstrap";

import FooterLogoIcon from "../commons/footer-logo-icon";
import FooterCloudsOrange from "../commons/footer-clouds-orange";
import FooterCloudsBlue from "../commons/footer-clouds-blue";
import {Translate} from "react-i18nify";
import {Link} from "react-router-dom";

const Footer = () => (
  <footer>
      <div className="clouds_orange">
          <FooterCloudsOrange />
      </div>
      <div className="clouds_blue">
          <FooterCloudsBlue />
      </div>
      <Container>
          <div className="footer-logo">
              <FooterLogoIcon />
          </div>
          <ul>
              <li>A+Kids ©</li>
              <li>
                  <Link to="/contact" target={'blank'}>
                      <Translate value="contact_link"/>
                  </Link>
              </li>
              <li>
                  <Link to="/privacy-terms" target={'blank'}>
                      <Translate value="privacy_terms_link"/>
                  </Link>
              </li>
          </ul>
      </Container>
  </footer>
);

export default Footer;
