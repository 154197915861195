import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import ApiMiddleware from './api/middleware';
import rootReducer from './reducers';

const middlewares = [ApiMiddleware, thunk];

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
