// @flow
import {defaultsDeep} from 'lodash';
import createReducer from '../../reducers/utils/createReducer';
import * as c from './types';
import * as conf from '../../constants/config';

export const defaultConfig = {
  background: conf.LOGIN_BACKGROUND,
  logo: conf.LOGO,
  backgroundColor: conf.LOGIN_BACKGROUND_COLOR,
  isEquity: false,
  showVotings: false,
  showDSO: false,
  showDividends: false,
  showInnerTrading: false,
};

export type ConfigStateType = $ReadOnly<{
  data: {
    background: string,
    logo: string,
    backgroundColor: string,
    isEquity: boolean,
    showVotings: boolean,
    showDSO: boolean,
    showDividends: boolean,
    showInnerTrading: boolean,
    primaryColor?: string,
  },
  error: null | {},
  isLoading: boolean,
}>;

export const initialState: ConfigStateType = {
  data: {},
  error: null,
  isLoading: false,
};

export const fallbackColor = '#e21b4f';

function setCssVariable(name: string, value: string): void {
  if (document.documentElement) {
    document.documentElement.style.setProperty(name, value);
  }
}

export default createReducer(initialState, {
  [c.GET_CONFIG_REQUEST]: (state: ConfigStateType): ConfigStateType => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_CONFIG_SUCCESS]: (state: ConfigStateType, {payload}): ConfigStateType => {
    if (payload.primaryColor) {
      setCssVariable('--primary', payload.primaryColor);
      setCssVariable('--primary-dark', payload.primaryColor);
      setCssVariable('--primary-light', payload.primaryColor);
      // setCssVariable('--blue', payload.primaryColor);
    } else {
      setCssVariable('--primary', fallbackColor);
      setCssVariable('--primary-dark', fallbackColor);
      setCssVariable('--primary-light', fallbackColor);
    }
    return {
      ...state,
      data: {
        ...defaultsDeep(payload, defaultConfig),
        background: payload.background || defaultConfig.background,
        logo: payload.logo || defaultConfig.logo,
        backgroundColor: payload.backgroundColor || defaultConfig.backgroundColor,
        primaryColor: payload.primaryColor || fallbackColor,
        isEquity: Boolean(Number(payload.isEquity)),
      },
      isLoading: false,
    };
  },
  [c.GET_CONFIG_ERROR]: (state: ConfigStateType, {payload}): ConfigStateType => ({
    data: {
      ...defaultConfig,
    },
    error: payload,
    isLoading: false,
  }),
});
