// @flow
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {logout} from '../ducks/auth/actions';
import Navbar from '../components/navbar';
import {userDuck} from '../ducks';
import type {ReduxStoreType} from '../types';

const ThemeNavbar = ({logout, toggleSidebarMenu, user, notifications, ...props}) => (
  <Navbar logout={logout} toggleSidebarMenu={toggleSidebarMenu} user={user} notifications={notifications} {...props} />
);

ThemeNavbar.propTypes = {
  logout: PropTypes.func.isRequired,
  toggleSidebarMenu: PropTypes.func.isRequired,
  user: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = (state: ReduxStoreType) => ({
  user: userDuck.selectors.userDataSelector(state),
});

const mapDispatchToProps = {
  logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThemeNavbar);
