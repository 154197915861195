// @flow
import createReducer from '../../reducers/utils/createReducer';
import * as c from './types';
import {defaultLang} from '../../constants';
import {translationsEn as usFallback} from '../../utils/i18n/locales/us.json';

export type I18nStateType = {
  source: string,
  locale: string,
  translations: {
    [string]: string[],
  },
  allIds: string[],
  isLoading: boolean,
  error: Error | null,
};

const initialState: I18nStateType = {
  source: defaultLang,
  locale: localStorage.getItem('i18n-locale') || defaultLang,
  translations: {},
  allIds: [],
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.GET_TRANSLATIONS_REQUEST]: state => ({
    ...state,
    error: null,
    isLoading: true,
  }),

  [c.GET_TRANSLATIONS_SUCCESS]: (state: I18nStateType, {payload}) => {
    return {
      ...state,
      translations: payload,
      allIds: Object.keys(payload),
      isLoading: false,
    }
  },

  [c.GET_TRANSLATIONS_ERROR]: (state, {payload}) => ({
    ...state,
    translations: {us: usFallback},
    allIds: ['us'],
    error: payload,
    isLoading: false,
  }),

  [c.SET_TRANSLATION_LOCALE]: (state, {payload}) => ({
    ...state,
    locale: payload,
  }),
});
