// @flow
import createReducer from '../../reducers/utils/createReducer';
import * as c from './types';

export type AuthReducerState = $ReadOnly<{
  isAuthenticated: boolean,
  isLoading: boolean,
  error: null | Error,
}>;

export const initialState = {
  isAuthenticated: false,
  isLoading: false,
  error: null,
};

export default createReducer(initialState, {
  [c.LOGIN_REQUEST]: (state: AuthReducerState): AuthReducerState => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.LOGIN_SUCCESS]: (state: AuthReducerState): AuthReducerState => ({
    ...state,
    isAuthenticated: true,
    isLoading: false,
  }),
  [c.LOGIN_ERROR]: (state: AuthReducerState, {payload}): AuthReducerState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),

  [c.LOGOUT_REQUEST]: (state: AuthReducerState): AuthReducerState => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.LOGOUT_SUCCESS]: (state: AuthReducerState): AuthReducerState => ({
    ...state,
    isAuthenticated: false,
    isLoading: false,
  }),
  [c.LOGOUT_ERROR]: (state: AuthReducerState, {payload}): AuthReducerState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),

  [c.REGISTER_REQUEST]: (state: AuthReducerState): AuthReducerState => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.REGISTER_SUCCESS]: (state: AuthReducerState): AuthReducerState => ({
    ...state,
    isAuthenticated: true,
    isLoading: false,
  }),
  [c.REGISTER_ERROR]: (state: AuthReducerState, {payload}): AuthReducerState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),

  [c.FORGOT_PASSWORD_REQUEST]: (state: AuthReducerState): AuthReducerState => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.FORGOT_PASSWORD_SUCCESS]: (state: AuthReducerState): AuthReducerState => ({
    ...state,
    isLoading: false,
  }),
  [c.FORGOT_PASSWORD_ERROR]: (state: AuthReducerState, {payload}): AuthReducerState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),

  [c.RESET_PASSWORD_REQUEST]: (state: AuthReducerState): AuthReducerState => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.RESET_PASSWORD_SUCCESS]: (state: AuthReducerState): AuthReducerState => ({
    ...state,
    isLoading: false,
  }),
  [c.RESET_PASSWORD_ERROR]: (state: AuthReducerState, {payload}): AuthReducerState => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
});
