import React from 'react';
import {connect} from 'react-redux';
import {ClipLoader} from 'react-spinners';
import {primary} from '../constants/colors';
import classnames from 'classnames';

const Spinner = props => (
  <div className={classnames('sweet-loading', {
    'relative-spinner': props.isRelative,
  })}>
    <ClipLoader className="clip-loader" sizeUnit="px" size={60} loading color={primary}/>
  </div>
);

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Spinner);
