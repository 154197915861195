import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import '../assets/scss/components/nothing-to-show/nothing-to-show.scss';
import {Translate} from 'react-i18nify';

const NothingToShow = ({text, Icon, color}) => (
  <div className="icon">
    {Icon && <Icon size={42} color={color}/>}
    <span className="icon-text">{text}</span>
  </div>
);

NothingToShow.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  Icon: PropTypes.func,
  color: PropTypes.string.isRequired,
};

NothingToShow.defaultProps = {
  text: <Translate value="nothingToShow_label"/>,
  Icon: () => null,
};

const mapStateToProps = ({config}) => ({
  color: config.data.primaryColor,
});

export default connect(mapStateToProps)(NothingToShow);
