// @flow
import {createActionsCurried} from '../../utils/actions';
import {appName} from '../../constants/config';

const moduleName = 'user';
const prefix = `${appName}/${moduleName}`;
const buildActions = createActionsCurried(prefix);

export const [GET_USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_ERROR] = buildActions(
  'GET_USER_PROFILE'
);

export const [SAVE_USER_PROFILE_REQUEST, SAVE_USER_PROFILE_SUCCESS, SAVE_USER_PROFILE_ERROR] = buildActions(
  'SAVE_USER_PROFILE'
);
export const [CHANGE_PROFILE_PASSWORD_REQUEST, CHANGE_PROFILE_PASSWORD_SUCCESS, CHANGE_PROFILE_PASSWORD_ERROR] = buildActions(
  'CHANGE_PROFILE_PASSWORD'
);
