import authDuck from '../auth';
import * as c from './types';
import createApiAction from '../../api/createApiAction';
import {
  I18n
} from "../../utils/i18n";
import toastr from "../../utils/toastr";

// eslint-disable-next-line import/prefer-default-export
export const getUserProfile = ({
    onSuccess = () => null,
    onError = () => null
  }) =>
  createApiAction({
    method: 'getUserProfile',
    requestAction: c.GET_USER_PROFILE_REQUEST,
    successAction: c.GET_USER_PROFILE_SUCCESS,
    errorHandler(dispatch, {
      response
    }) {
      const errorMessage = response ? response.data.error : '';

      dispatch({
        type: c.GET_USER_PROFILE_ERROR,
        payload: errorMessage,
      });
      onError();
    },
    transformResponse: ({
      data
    }) => data,
    afterSuccess: dispatch => {
      dispatch({
        type: authDuck.types.LOGIN_SUCCESS,
      });
      onSuccess();
    },
  });

export const saveUserProfile = (dataToSend) =>
  createApiAction({
    method: 'saveUserProfile',
    requestAction: c.SAVE_USER_PROFILE_REQUEST,
    successAction: c.SAVE_USER_PROFILE_SUCCESS,
    params: dataToSend,
    errorHandler(dispatch, {
      response
    }) {
      toastr.error(I18n.t('system_popup'), I18n.t(response.data.error));

      dispatch({
        type: c.SAVE_USER_PROFILE_ERROR,
        payload: response.data.error,
      });
    },
    transformResponse: ({
      data
    }) => data,
    afterSuccess: (
      
      dispatch, {
        data: {
          ...userData
        },
      }
    ) => {
      // dispatch(getUserProfile());
      dispatch({
        type: c.GET_USER_PROFILE_SUCCESS,
        payload: userData,
      });
      toastr.success(I18n.t('system_popup'), I18n.t('הפרטים עודכנו בהצלחה'));

    },
  });

export const changeProfilePassword = (dataToSend) =>
  createApiAction({
    method: 'changeProfilePassword',
    requestAction: c.CHANGE_PROFILE_PASSWORD_REQUEST,
    successAction: c.CHANGE_PROFILE_PASSWORD_SUCCESS,
    params: dataToSend,
    errorHandler(dispatch, {
      response
    }) {
      toastr.error(I18n.t('system_popup'), I18n.t(response.data.error));

      dispatch({
        type: c.CHANGE_PROFILE_PASSWORD_ERROR,
        payload: response.data.error,
      });
    },
    transformResponse: ({
      data
    }) => data,
    afterSuccess: () => {
      toastr.success(I18n.t('system_popup'), I18n.t('password_changed_success'));
    },
  });