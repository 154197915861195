import Client from './client';
import {LS_TOKEN_KEY,getCookie} from '../constants';
// import store from '../store';
// import { logout } from '../actions/auth';

let endpoint = process.env.REACT_APP_ENDPOINT;
if (endpoint && !endpoint.startsWith('http')) {
  endpoint = `https://${endpoint}` ;
}
const baseUrl = endpoint ||  'https://api.apluskids.tv/hebrew/public/api/v2'   /* 'http://localhost:8080/api/v2' */

const api = Client.create(baseUrl);

if (typeof window !== 'undefined') {
  //const token = window.sessionStorage.getItem(LS_TOKEN_KEY);
  const token = getCookie(LS_TOKEN_KEY);
  if (token) {
    api.setJwtToken(token);
 /*    var cname='__jwtAppToken__';
    
    var dt, expires;
    dt = new Date();
    dt.setTime(dt.getTime()+(1*24*60*60*1000));
    expires = "; expires="+dt.toGMTString();
    document.cookie = cname+"="+token+expires+'; domain=apluskids.co.il'; */
  }
}

api.addErrorInterceptor(error => {
  if (error.response && error.response.status === 401) {
    // TODO dispatch logout
    // store.dispatch(logout());
  }

  return Promise.reject(error);
});

export const BASE_URL = baseUrl;

export default api;
