import api from './index';
import ApiClient from './client';

let cachedRequests = [];

const middleware = ({dispatch}) => next => async action => {
  if (!action[ApiClient.ACTION_KEY]) {
    next(action);
    return;
  }

  const {
    method,
    params,
    requestAction,
    successAction,
    errorAction,
    transformResponse,
    afterSuccess,
    useCache,
    errorHandler,
  } = action;

  try {
    const cacheKey = JSON.stringify({params, method});

    if (useCache) {
      if (cachedRequests.includes(cacheKey)) {
        return;
      }

      cachedRequests.push(cacheKey);
    }

    dispatch({type: requestAction, payload: params, meta: {method, useCache}});

    const response = await api[method](params);
    cachedRequests = cachedRequests.filter(key => key !== cacheKey);

    dispatch({
      type: successAction,
      payload: transformResponse ? transformResponse(response) : response,
    });
    if (afterSuccess) {
      afterSuccess(dispatch, response);
    }
  } catch (error) {
    if (errorHandler) {
      errorHandler(dispatch, error);
    } else {
      dispatch({
        type: errorAction,
        error,
      });
    }
  }
};

export default middleware;
