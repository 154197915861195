import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from 'react-router-dom';
import {withAuth} from '../hocs';
import {FeatureFlagComponent} from '../components';

import PrintLayout from '../layouts/print-layout';

const PrintLayoutRoute = ({render, auth: {isAuthenticated}, flag, showOnlyWhenDisabled, ...rest}) => {
  const route = (
    <Route
      {...rest}
      render={matchProps =>
        isAuthenticated ? <PrintLayout>{render(matchProps)}</PrintLayout> : <Redirect to="/login"/>
      }
    />
  );
  return flag ? (
    <FeatureFlagComponent name={flag} showOnlyWhenDisabled={showOnlyWhenDisabled}>
      {route}
    </FeatureFlagComponent>
  ) : (
    route
  );
};

PrintLayoutRoute.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
  }).isRequired,
  render: PropTypes.func.isRequired,
  flag: PropTypes.string,
  showOnlyWhenDisabled: PropTypes.bool,
};

PrintLayoutRoute.defaultProps = {
  flag: undefined,
  showOnlyWhenDisabled: false,
};

export default withAuth(PrintLayoutRoute);
