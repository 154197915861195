const languageFromCode = {
  us: 'English',
};

export const i18nLocaleSelectors = state => state.i18n.locale;

export const getTranslationOptions = state => {
  const {allIds} = state.i18n;

  return allIds.map(key => ({
    country: key,
    label: languageFromCode[key] || key.toUpperCase(),
  }));
};
