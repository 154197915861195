import {connect} from 'react-redux';
import {branch, compose, renderNothing} from 'recompose';
import {isEmpty} from 'lodash';

const mapStateToProps = ({config: {isLoading, data}}) => ({isLoading, data});

export default compose(
  connect(mapStateToProps),
  branch(({data, isLoading}) => isEmpty(data) || isLoading, renderNothing)
);
