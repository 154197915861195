import PropTypes from 'prop-types';
import React, {Component, createContext} from 'react';

import templateConfig from '../../templateConfig';

const FoldedContentContext = createContext();

class FoldedContentProvider extends Component {
  state = {
    foldedContent: templateConfig.sidebar.collapsed,
  };

  makeFullContent = () => {
    this.setState(() => ({
      foldedContent: true,
    }));
  };

  makeNormalContent = () => {
    this.setState(() => ({
      foldedContent: false,
    }));
  };

  render() {
    const {children} = this.props;
    return (
      <FoldedContentContext.Provider
        value={{
          ...this.state,
          makeFullContent: this.makeFullContent,
          makeNormalContent: this.makeNormalContent,
        }}
      >
        {children}
      </FoldedContentContext.Provider>
    );
  }
}

const FoldedContentConsumer = FoldedContentContext.Consumer;

FoldedContentProvider.propTypes = {
  children: PropTypes.node,
};

FoldedContentProvider.defaultProps = {
  children: null,
};

export {FoldedContentProvider, FoldedContentConsumer};
