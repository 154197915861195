import React from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withDialog } from '../../hocs';
import { getUserInvoice } from '../../ducks/billing/actions';
import { Translate } from "../../utils/i18n";
import ReceiptPopup from "../../components/receipt-popup";
import { billingDuck } from '../../ducks';
import LogoIcon from "../../components/commons/logo-icon";

class Reciept extends React.Component {

  componentDidMount() {
    const { getUserInvoice, match } = this.props;
    let invoiceId = match.params.id ? match.params.id : 0;
    getUserInvoice(invoiceId);
  }

  render() {
    const {
      invoiceData, match
    } = this.props;
    let invoiceId = match.params.id ? match.params.id : 0;
    return (
      <>
        <Row>
          <Col xs="12" md="12" xl="12">
            <Card>
              <CardBody>
							<div className="card-top">
									<CardTitle tag="h1">
										<Translate value="reciept_title" />
										<div className="card-for">
										<Translate value="reciept_for" /> <span className="card-email">efy.michael@gmail.com</span>
										</div>
									</CardTitle>
									<div className="card-btn">
										<Link to={`/reciept-print/${invoiceId}`}  target="blank" className="btn btn-blue btn-xl"><Translate value="reciept_print" /></Link>
										<div className="card-logo">
											<LogoIcon />
										</div>
									</div>
								</div>
                <Table hidden={true}>
                  <tbody>
                    <tr>
                      <td width="50">*First Name</td>
                      <td width="100">Vise ending in 4567, exp 12/2021</td>
                      <td width="30">Update</td>
                      <td width="30">Update</td>
                    </tr>
                    <tr>
                      <td width="50">*First Name</td>
                      <td width="100">Vise ending in 4567, exp 12/2021</td>
                      <td width="30">Update</td>
                      <td width="30">Update</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardBody>
                <Table>
                  <tbody>
										<tr>
											<td className="color_blue">Date</td>
											<td>10 april 2020</td>
										</tr>
										<tr>
											<td className="color_blue">Number</td>
											<td>number here</td>
										</tr>
										<tr>
											<td className="color_blue">Retailer</td>
											<td>retailer here</td>
										</tr>
										<tr>
											<td className="color_blue">Address</td>
											<td>10 ap36 Some Gloucester Avenue London NW1 7BB, United Kingdom</td>
										</tr>
										<tr>
											<td className="color_blue">VAT number</td>
											<td>30298572iSKJDHF293786</td>
										</tr>
										<tr>
											<td className="color_blue">Product</td>
											<td>Monthly subscription</td>
										</tr>
										<tr>
											<td className="color_blue">Total Tax</td>
											<td><span>$</span>0.0</td>
										</tr>
										<tr>
											<td className="color_blue">Total</td>
											<td><span>$</span>3.5</td>
										</tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
const mapDispatchToProps = {
  getUserInvoice,
};
const mapStateToProps = state => ({
  invoiceData: billingDuck.selectors.currentInvoiceSelector(state),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDialog(Reciept));
