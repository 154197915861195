import React, {Children, cloneElement, Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Badge} from 'reactstrap';

import '../../assets/scss/components/sidebar/sidemenuHelper.scss';

class SidebarMenu extends PureComponent {
  state = {
    selectedMenuItem: -1,
  };

  static MenuSingleItem = props => (
    <li
      className="nav-item"
      role="tab"
      onClick={() => {
        props.closeOther(props.notifications);
        props.handleClick();
      }}
    >
      {props.children}
      {props.badgeText ? (
        <Badge className="menu-item-badge menu-single-item-badge" color={props.badgeColor} pill>
          {props.badgeText}
        </Badge>
      ) : (
        ''
      )}
    </li>
  );

  static MenuMultiItems = props => (
    <Fragment>
      <li className={`has-sub nav-item  ${props.selected === true && props.collapsedSidebar === false ? 'open' : ''}`}>
        <a
          href="/"
          onClick={e => {
            e.preventDefault();
            props.toggle(props.notifications);
          }}
        >
          <span className="menu-item-text d-inline text-capitalize">{props.name}</span>
          {props.badgeText ? (
            <Badge className="menu-item-badge menu-multiple-item-badge" color={props.badgeColor} pill>
              {props.badgeText}
            </Badge>
          ) : (
            ''
          )}

          <span className="item-arrow">{props.ArrowRight}</span>
        </a>

        <ul>
          {Children.map(props.children, child => {
            if (child.props.children.type === undefined) {
              return <li>{child}</li>;
            }
            return (
              <li
                role="tab"
                onClick={() => {
                  props.handleClick();
                }}
              >
                {child}
              </li>
            );
          })}
        </ul>
      </li>
    </Fragment>
  );

  static SidebarMenuMultiItems = props => (
    <Fragment>
      <li className={`has-sub nav-item  ${props.selected === true && props.collapsedSidebar === false ? 'open' : ''}`}>
        {/* eslint-disable-next-line */}
        <a
          onClick={() => {
            props.toggle(props.index);
          }}
        >
          <span
            className={`menu-item-text text-capitalize d-inline ${!props.isApproved ? 'menu-item-text--disabled' : ''}`}
          >
            {props.name}
          </span>
          {props.badgeText ? (
            <Badge className="menu-item-badge menu-multiple-item-badge" color={props.badgeColor} pill>
              {props.badgeText}
            </Badge>
          ) : (
            ''
          )}

          <span className="item-arrow">{props.ArrowRight}</span>
        </a>

        <ul>
          {Children.map(props.children, child => {
            if (child.props.children.type === undefined) {
              return <li>{child}</li>;
            }
            return (
              <li
                role="tab"
                onClick={() => {
                  props.handleClick();
                }}
              >
                {child}
              </li>
            );
          })}
        </ul>
      </li>
    </Fragment>
  );

  toggle = itemIndex => {
    const {selectedMenuItem} = this.state;
    if (selectedMenuItem === itemIndex) {
      this.setState({
        selectedMenuItem: -1,
      });
    } else {
      this.setState({selectedMenuItem: itemIndex});
    }
  };

  closeOther = singleItemIndex => {
    this.setState({selectedMenuItem: singleItemIndex});
  };

  handleClick = () => {
    const {toggleSidebarMenu} = this.props;

    toggleSidebarMenu('close');
  };

  render() {
    const {children} = this.props;
    const Nodes = Children.map(children, (child, index) => {
      const {selectedMenuItem} = this.state;

      if (child.type === SidebarMenu.MenuSingleItem) {
        return cloneElement(child, {
          closeOther: this.closeOther,
          handleClick: this.handleClick,
          index,
          selected: index === selectedMenuItem,
          ...child.props,
        });
      }
      if (child.type === SidebarMenu.MenuMultiItems) {
        return cloneElement(child, {
          toggle: this.toggle,
          handleClick: this.handleClick,
          index,
          selected: index === selectedMenuItem,
          collapsedSidebar: child.props.collapsedSidebar !== undefined ? child.props.collapsedSidebar : false,
          ...child.props,
        });
      }
      if (child.type === SidebarMenu.SidebarMenuMultiItems) {
        return cloneElement(child, {
          toggle: this.toggle,
          handleClick: this.handleClick,
          index,
          selected: index === selectedMenuItem,
          collapsedSidebar: child.props.collapsedSidebar !== undefined ? child.props.collapsedSidebar : false,
          ...child.props,
        });
      }

      return null;
    });
    return (
      <div className="nav-container">
        <ul id="main-menu-navigation" className="navigation navigation-main">
          {Nodes}
        </ul>
      </div>
    );
  }
}

SidebarMenu.propTypes = {
  toggleSidebarMenu: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

SidebarMenu.defaultProps = {
  children: null,
};

export default SidebarMenu;
