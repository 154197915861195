import React from 'react';
import {compose, withHandlers} from 'recompose';
import {Button} from 'reactstrap';
import {Translate} from 'react-i18nify';
import withDialog from './withDialog';

export default compose(
  withDialog,
  withHandlers({
    showDialog: ({showDialog, hideDialog}) => args =>
      showDialog({
        ...args,
        renderBody: () => (
          <div>
            <Button color="danger" className="mr-4 w-25" onClick={hideDialog}>
              <Translate value="no_option"/>
            </Button>
            <Button color="success" className="w-25" onClick={args.onConfirm || hideDialog}>
              <Translate value="yes_option"/>
            </Button>
          </div>
        ),
      }),
  })
);
