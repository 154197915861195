// @flow
import createReducer from '../../reducers/utils/createReducer';
import * as c from './types';

export const initialState = {
  isLoading: false,
  isLoadingInvoices: false,
  isLoadingPrices: false,
  isLoadingPayment: false,
  error: null,
  errorPayment: null,
  invoices: [],
  types: [],
  prices: [],
};

export default createReducer(initialState, {
  [c.GET_USER_INVOICES_REQUEST]: (state) => ({
    ...state,
    error: null,
    isLoadingInvoices: true,
  }),
  [c.GET_USER_INVOICES_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isLoadingInvoices: false,
      invoices: payload
    }
  },
  [c.GET_USER_INVOICES_ERROR]: (state, {payload}) => ({
    ...state,
    error: payload,
    isLoadingInvoices: false,
  }),
  [c.GET_USER_INVOICE_REQUEST]: (state) => ({
    ...state,
    error: null,
    isLoadingInvoiceData: true,
  }),
  [c.GET_USER_INVOICE_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isLoadingInvoiceData: false,
      invoiceData: payload
    }
  },
  [c.GET_USER_INVOICE_ERROR]: (state, {payload}) => ({
    ...state,
    error: payload,
    isLoadingInvoiceData: false,
  }),
  [c.GET_AVAILABLE_PRODUCTS_REQUEST]: (state) => ({
    ...state,
    error: null,
    isLoadingPrices: true,
  }),
  [c.GET_AVAILABLE_PRODUCTS_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isLoadingPrices: false,
      prices: payload
    }
  },
  [c.GET_AVAILABLE_PRODUCTS_ERROR]: (state, {payload}) => ({
    ...state,
    error: payload,
    isLoadingPrices: false,
  }),
  [c.SUBMIT_SUBSCRIPTION_PAYMENT_REQUEST]: (state) => ({
    ...state,
    errorPayment: null,
    isLoadingPayment: true,
  }),
  [c.SUBMIT_SUBSCRIPTION_PAYMENT_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isLoadingPayment: false,
    }
  },
  [c.SUBMIT_SUBSCRIPTION_PAYMENT_ERROR]: (state, {payload}) => ({
    ...state,
    errorPayment: payload,
    isLoadingPayment: false,
  }),
  [c.CANCEL_SUBSCRIPTION_REQUEST]: (state) => ({
    ...state,
    errorPayment: null,
    isLoadingPayment: true,
  }),
  [c.CANCEL_SUBSCRIPTION_SUCCESS]: (state, {payload}) => {
    return {
      ...state,
      isLoadingPayment: false,
    }
  },
  [c.CANCEL_SUBSCRIPTION_ERROR]: (state, {payload}) => ({
    ...state,
    errorPayment: payload,
    isLoadingPayment: false,
  }),
});
