import React from 'react';
import {compose, withProps} from 'recompose';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {Translate} from 'react-i18nify';
import Paginator from './paginator';

import '../assets/scss/components/table/table.scss';

const PaginationComponent = props => {
  const {total, limit, onFetchData} = props;
  return (
    <div className="w-100 d-flex align-items-center justify-content-center">
      {total > 0 && <Paginator totalItems={total} pageSize={limit} maxPaginationNumbers={3} onSelect={onFetchData}/>}
    </div>
  );
};

PaginationComponent.propTypes = {
  total: PropTypes.number,
  limit: PropTypes.number,
  onFetchData: PropTypes.func.isRequired,
};

PaginationComponent.defaultProps = {
  total: 0,
  limit: 10,
};

export default compose(
  withProps({
    defaultPageSize: 10,
    PaginationComponent,
    sortable: false,
    noDataText: <Translate value="no_rows_found"/>,
  })
)(ReactTable);
