import {connect} from 'react-redux';
import {forgotPassword, login, register, resetPassword} from '../ducks/auth/actions';

const mapStateToProps = ({auth}) => ({auth});
const mapDispatchToProps = {
  login,
  register,
  forgotPassword,
  resetPassword,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
);
