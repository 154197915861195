import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {TooltipWrapper} from '../tooltip';

import '../../assets/scss/components/element-header/element-header.scss';

const ElementHeader = ({title, aside, className, tooltip}) => (
  <div className={classnames('element-header-wrapper', className)}>
    <TooltipWrapper tooltipProps={tooltip}>
      <h6 className="element-header text-bold-500">{title}</h6>
    </TooltipWrapper>
    {aside && <div>{aside}</div>}
  </div>
);

ElementHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  aside: PropTypes.node,
  className: PropTypes.string,
  tooltip: PropTypes.objectOf(PropTypes.any),
};

ElementHeader.defaultProps = {
  aside: null,
  className: '',
  tooltip: {},
};

export default ElementHeader;
