import React, {PureComponent} from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {FoldedContentConsumer, FoldedContentProvider} from '../utils/context/toggleContentContext';
import templateConfig from '../templateConfig';

import {userDuck} from '../ducks';

import Sidebar from '../components/sidebar';
import Footer from '../components/footer';
import Navbar from '../containers/navbar';
import {Container} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import 'react-perfect-scrollbar/dist/css/styles.css';
import HeaderLogo from '../components/commons/header-logo';
class MainLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.element.isRequired,
    userData: PropTypes.shape({
      profile: PropTypes.objectOf(PropTypes.any),
      role: PropTypes.string,
    }),
  };

  static defaultProps = {
    userData: {
      profile: {},
      role: '',
    },
  };

  state = {
    width: window.innerWidth,
    sidebarState: 'close',
    sidebarSize: '',
    layout: '',
  };

  get alertColor() {
    return 'primary';
  }

  get alertText() {
    return '';
  }

  get scrollbarOptions() {
    return {
      suppressScrollX: true
    }
  }

  componentDidMount() {
    if (window !== 'undefined') {
      window.addEventListener('resize', this.updateWidth, false);
    }
  }

  componentWillUnmount() {
    if (window !== 'undefined') {
      window.removeEventListener('resize', this.updateWidth, false);
    }
  }

  updateWidth = () => {
    this.setState({width: window.innerWidth});
  };

  handleSidebarSize = sidebarSize => {
    this.setState({sidebarSize});
  };

  handleLayout = layout => {
    this.setState({layout});
  };

  toggleSidebarMenu = sidebarState => {
    this.setState({sidebarState});
  };

  render() {
    const {children} = this.props;
    const {sidebarSize, sidebarState, layout} = this.state;

    return (
      <FoldedContentProvider>
        <FoldedContentConsumer>
          {() => (
            <div
              className={classnames('wrapper', {
                'main-layout': true,
                [`${templateConfig.sidebar.size}`]: sidebarSize === '',
                [`${sidebarSize}`]: sidebarSize !== '',
                [`${templateConfig.layoutColor}`]: layout === '',
                [`${layout}`]: layout !== '',
              })}
            >
              <Container className={'main-wrapper'}>
                
                <Navbar {...this.props} toggleSidebarMenu={this.toggleSidebarMenu} sidebarState/>
                <div className="card-outer main-wrap">
                  <HeaderLogo />
                <div className="card-wrapper">
                  <Sidebar
                    {...this.props}
                    toggleSidebarMenu={this.toggleSidebarMenu}
                    sidebarState={sidebarState}
                    handleSidebarSize={this.handleSidebarSize}
                    handleLayout={this.handleLayout}
                  />
                  <PerfectScrollbar 
                    ref = {(ref) => { this._scrollBarRef = ref; }}
                    className="main-content" options={this.scrollbarOptions}>
                    <main className="position-relative" 
                      onUpdateSize = {() => { this._scrollBarRef.updateScroll(); }}>{children}</main>
                  </PerfectScrollbar>
                </div>
                </div>
              </Container>
              <Footer {...this.props} />
            </div>
          )}
        </FoldedContentConsumer>
      </FoldedContentProvider>
    );
  }
}

const mapStateToProps = state => ({
  userData: userDuck.selectors.userDataSelector(state),
});

export default connect(
  mapStateToProps,
  {}
)(MainLayout);
