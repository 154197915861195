// @flow
import { GET_USER_PROFILE_SUCCESS } from '../user/types';
import * as c from './types';
import api from '../../api';
import createApiAction from '../../api/createApiAction';
import history from '../../utils/history';
import toastr from '../../utils/toastr';
import { I18n } from '../../utils/i18n';
import { getUserProfile } from '../user/actions';
import { LS_TOKEN_KEY, setCookie, getCookie } from '../../constants';

const saveToken = token => {
  if (typeof window !== 'undefined') {
    //window.sessionStorage.setItem(LS_TOKEN_KEY, token);
    setCookie(LS_TOKEN_KEY, token, 7);
    api.setJwtToken(token);
  }
};

export const login = data =>
  createApiAction({
    method: 'login',
    requestAction: c.LOGIN_REQUEST,
    successAction: c.LOGIN_SUCCESS,
    params: data,
    errorHandler(dispatch, { response }) {
      const errorMessage =
        response && response.status !== 403 ? I18n.t(response.data.error) : I18n.t('connection_error');
      toastr.error(I18n.t('system_popup'), errorMessage);

      dispatch({
        type: c.LOGIN_ERROR,
        payload: errorMessage,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: (
      dispatch,
      {
        data: {
          jwt: { token },
          user,
          crc,
          has_subscription,
          subscription,
          is_old_subscription,
          ...userData
        },
      }
    ) => {
      saveToken(token);
      if (is_old_subscription) {
        setCookie('isOldSubscription', true, 7);
        if(subscription){

          setCookie('subscription', JSON.stringify(subscription), 7);
        }
      }

      //--------------------------------------
      let k = {
        user,
        crc,
        token,
        has_subscription,
        userId: user.id,
        name: user.first_name + ' ' + user.last_name,
      };
      setCookie('user_data', JSON.stringify(k), 7);
      //window.parent.window.dispatchEvent(new Event('login'));
      /*  var url = new URL(window.location.href);
      if (url.searchParams.get('iframe')) { */
      k.messageType = 'login';
      window.parent.postMessage(k, 'https://www.apluskids.co.il');
      /*   } */
      //if we are in an iframe
      if (window.location !== window.parent.location) {
        toastr.success(I18n.t('success'));
        return;
      }
      dispatch(getUserProfile(userData));
      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: userData,
      });
    },
  });

export const loginFb = data =>
  createApiAction({
    method: 'loginFb',
    requestAction: c.LOGIN_REQUEST,
    successAction: c.LOGIN_SUCCESS,
    params: data,
    errorHandler(dispatch, { response }) {
      console.log(response);
      const errorMessage = response && response.status !== 403 ? response.data.error : I18n.t('connection_error');
      toastr.error(I18n.t('system_popup'), errorMessage);

      dispatch({
        type: c.LOGIN_ERROR,
        payload: errorMessage,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: (
      dispatch,
      {
        data: {
          jwt: { token },
          ...userData
        },
      }
    ) => {
      saveToken(token);

      dispatch(getUserProfile(userData));
      dispatch({
        type: GET_USER_PROFILE_SUCCESS,
        payload: userData,
      });
    },
  });

export const register = data =>
  createApiAction({
    method: 'register',
    requestAction: c.REGISTER_REQUEST,
    successAction: c.REGISTER_SUCCESS,
    errorAction: c.REGISTER_ERROR,
    params: data,
    errorHandler(dispatch, { response }) {
      const errorMessage = response ? response.data.error : 'error';
      toastr.error(I18n.t('system_popup'), I18n.t(errorMessage));
      dispatch({
        type: c.REGISTER_ERROR,
        payload: errorMessage,
      });
    },
    transformResponse: () => data,
    afterSuccess: (
      dispatch,
      {
        data: {
          jwt: { token },
          ...userData
        },
      }
    ) => {
      saveToken(token);

      let k = {
        user:userData.user,
        crc:userData.crc,
        token,
        has_subscription :false,
        userId: userData.user.id,
        name: userData.user.first_name + ' ' + userData.user.last_name,
      };
      setCookie('user_data', JSON.stringify(k), 7);
      k.messageType = 'login';
      window.parent.postMessage(k, 'https://www.apluskids.co.il');
      //if we are in an iframe
      if (window.location !== window.parent.location) {
        toastr.success(I18n.t('success'));
        return;
      }

      dispatch(getUserProfile(userData));
    },
  });

export const logout = () =>
  createApiAction({
    method: 'logout',
    requestAction: c.LOGOUT_REQUEST,
    successAction: c.LOGOUT_SUCCESS,
    errorHandler(dispatch, { response }) {
      /* const errorMessage = response.data.error;
      toastr.error(I18n.t('system_popup'), errorMessage);
      dispatch({
        type: c.LOGOUT_ERROR,
        payload: errorMessage,
      }); */
      console.log('error');
      setCookie(LS_TOKEN_KEY, '', -1);
      setCookie('user_data', '', -1);
      setCookie('subscription', '', -1);
      api.setJwtToken(null);
      setCookie('isOldSubscription', '', -1);
      if (getCookie('isIframe')) {
        window.parent.postMessage({ messageType: 'logout' }, 'https://www.apluskids.co.il');
        return;
      }
    },
    transformResponse: ({ data }) => data,
    afterSuccess: () => {
      setCookie(LS_TOKEN_KEY, '', -1);
      setCookie('user_data', '', -1);
      setCookie('subscription', '', -1);
      api.setJwtToken(null);
      setCookie('isOldSubscription', '', -1);

      if (getCookie('isIframe')) {
        console.log('isIframe');
        window.parent.postMessage({ messageType: 'logout' }, 'https://www.apluskids.co.il');
      }
      //window.parent.window.dispatchEvent(new Event('login'));
      if (typeof window !== 'undefined') {
      }
    },
  });

export const forgotPassword = data =>
  createApiAction({
    method: 'forgotPassword',
    requestAction: c.FORGOT_PASSWORD_REQUEST,
    successAction: c.FORGOT_PASSWORD_SUCCESS,
    params: data,
    errorHandler(dispatch, { response }) {
      const errorMessage = response.data.error;
      toastr.error(I18n.t('error'), I18n.t(errorMessage));
      dispatch({
        type: c.FORGOT_PASSWORD_ERROR,
        payload: errorMessage,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: () => toastr.success(I18n.t('system_popup'), I18n.t('resetPasswordEmailWasSent_popup')),
  });

export const resetPassword = data =>
  createApiAction({
    method: 'resetPassword',
    requestAction: c.RESET_PASSWORD_REQUEST,
    successAction: c.RESET_PASSWORD_SUCCESS,
    params: data,
    errorHandler(dispatch, { response }) {
      const errorMessage = response.data.error;
      toastr.error(I18n.t('system_popup'), errorMessage);
      dispatch({
        type: c.RESET_PASSWORD_ERROR,
        payload: errorMessage,
      });
    },
    transformResponse: ({ data }) => data,
    afterSuccess: () => {
      history.push('/');
      toastr.success(I18n.t('system_popup'), I18n.t('passwordWasSuccessfullyChanged_popup'));
    },
  });

export const registerRequest = () => ({
  type: c.REGISTER_REQUEST,
});

export const registerSuccess = payload => ({
  type: c.REGISTER_SUCCESS,
  payload,
});

export const registerError = payload => ({
  type: c.REGISTER_ERROR,
  payload,
});

export const forgotPasswordRequest = () => ({
  type: c.FORGOT_PASSWORD_REQUEST,
});

export const forgotPasswordSuccess = payload => ({
  type: c.FORGOT_PASSWORD_SUCCESS,
  payload,
});

export const forgotPasswordError = payload => ({
  type: c.FORGOT_PASSWORD_ERROR,
  payload,
});

export const resetPasswordRequest = () => ({
  type: c.RESET_PASSWORD_REQUEST,
});

export const resetPasswordSuccess = payload => ({
  type: c.RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordError = payload => ({
  type: c.RESET_PASSWORD_ERROR,
  payload,
});
