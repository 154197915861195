// @flow
import {createActionsCurried} from '../../utils/actions';
import {appName} from '../../constants/config';

const moduleName = 'billing';
const prefix = `${appName}/${moduleName}`;
const buildActions = createActionsCurried(prefix);

export const [GET_USER_INVOICES_REQUEST, GET_USER_INVOICES_SUCCESS, GET_USER_INVOICES_ERROR] = buildActions(
  'GET_USER_INVOICES'
);

export const [GET_USER_INVOICE_REQUEST, GET_USER_INVOICE_SUCCESS, GET_USER_INVOICE_ERROR] = buildActions(
  'GET_USER_INVOICE'
);

export const [GET_AVAILABLE_PRODUCTS_REQUEST, GET_AVAILABLE_PRODUCTS_SUCCESS, GET_AVAILABLE_PRODUCTS_ERROR] = buildActions(
  'GET_AVAILABLE_PRODUCTS'
);

export const [SUBMIT_SUBSCRIPTION_PAYMENT_REQUEST, SUBMIT_SUBSCRIPTION_PAYMENT_SUCCESS, SUBMIT_SUBSCRIPTION_PAYMENT_ERROR] = buildActions(
  'SUBMIT_SUBSCRIPTION_PAYMENT'
);
export const [CANCEL_SUBSCRIPTION_REQUEST, CANCEL_SUBSCRIPTION_SUCCESS, CANCEL_SUBSCRIPTION_ERROR] = buildActions(
  'CANCEL_SUBSCRIPTION'
);
