import React, {Component, createContext} from 'react';
import PropTypes from 'prop-types';
import Dialog from '../../components/dialog';

const DialogContext = createContext(null);

class DialogProvider extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  state = {
    isOpen: false,
    type: 'success',
    title: '',
    renderBody: () => null,
  };

  showDialog = ({type = 'success', title, renderBody = () => null}) => {
    this.setState({
      isOpen: true,
      type,
      title,
      renderBody,
    });
  };

  hideDialog = () => {
    this.setState({isOpen: false});
  };

  render() {
    const {isOpen, type, title, renderBody} = this.state;
    const {children} = this.props;

    return (
      <DialogContext.Provider value={{showDialog: this.showDialog, hideDialog: this.hideDialog}}>
        {children}
        <Dialog isOpen={isOpen} type={type} title={title} renderBody={renderBody}/>
      </DialogContext.Provider>
    );
  }
}

const DialogConsumer = DialogContext.Consumer;

export {DialogProvider, DialogConsumer};
