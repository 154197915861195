import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getConfigData} from '../../ducks/config/selectors';

const FeatureFlag = ({featureToggleList, showOnlyWhenDisabled, name, children}) => {
  if (typeof featureToggleList[name] === 'undefined') {
    global.console.warn(`${name} doesn't exist in state.config.data`);
  }

  const allowedEnv = process.env.REACT_APP_SHOW_FLAGS || process.env.NODE_ENV === 'development';

  const toggleState = featureToggleList[name];
  const showContent = !!toggleState === !showOnlyWhenDisabled || allowedEnv;
  return showContent ? children : null;
};

FeatureFlag.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  showOnlyWhenDisabled: PropTypes.bool,
};

FeatureFlag.defaultProps = {
  showOnlyWhenDisabled: false,
  children: null,
};

const mapStateToProps = state => ({
  featureToggleList: getConfigData(state),
});

export default connect(mapStateToProps)(FeatureFlag);
