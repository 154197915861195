export const truncate = (str, length) => str.substring(0, length) + (str.length > length ? '...' : '');

export const formatBigNumbers = (nr = 0) => {
  const legend = {
    0: {
      number: nr,
    },
    1: {
      number: nr / 1000,
      label: 'K',
    },
    2: {
      number: nr / 1000 ** 2,
      label: 'M',
    },
    3: {
      number: nr / 1000 ** 3,
      label: 'B',
    },
  };
  let times = 0;
  let tempNr = nr;

  while (tempNr / 1000 >= 1) {
    times += 1;
    tempNr /= 1000;
  }
  const {number, label} = legend[times];
  return `${+number.toFixed(2)}${label ? `${label}` : ''}`;
};

export const formatFileName = str =>
  str
    .split('')
    .map(c => c.replace(/[\u{0080}-\u{FFFF}]/u, String.fromCharCode(Math.floor(Math.random() * 25 + 97))))
    .join('');

export {default as History} from './history';
