import * as AuthActions from '../ducks/auth/actions';
import * as UserActions from '../ducks/user/actions';
// import * as NotificationActions from '../ducks/notifications/actions';
import * as TranslationActions from '../ducks/i18n/actions';
import * as BillingActions from '../ducks/billing/actions';

export default {
  ...AuthActions,
  ...UserActions,
  // ...NotificationActions,
  ...TranslationActions,
  ...BillingActions,
};
