import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Row, CustomInput, Label} from 'reactstrap';
import {I18n, Translate} from 'react-i18nify';
import FacebookLogin from 'react-facebook-login';

import AuthOverlay from '../components/auth-overlay';
import LoadingButton from '../components/loading-button';
import HeaderLogo from '../components/commons/header-logo';

const formSchema = Yup.object().shape({
  password: Yup.string()
    .required(<Translate value="fieldIsRequired_message"/>)
    .min(4, <Translate value="passwordMustHaveAtLeast4Characters_message"/>)
    .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d_@.!\/#&+-]{6,}$/, <Translate value="atLeastOneNumberAndLetter_message"/>),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], <Translate value="passwordDontMatch_message"/>)
    .required(<Translate value="fieldIsRequired_message"/>),
  email: Yup.string()
    .email(<Translate value="invalidEmail_message"/>)
    .required(<Translate value="fieldIsRequired_message"/>),
  first_name: Yup.string()
    .required(<Translate value="fieldIsRequired_message"/>),
  last_name: Yup.string()
    .required(<Translate value="fieldIsRequired_message"/>),
});

type LoginStateType = {
  isChecked: boolean,
};

class Register extends Component {
  responseFacebook = () => {

  };

  state = {
    isChecked: false,
  };

  handleToggleCheckbox = () => {
    const {isChecked} = this.state;
    this.setState({isChecked: !isChecked});
  };

  render() {
    const {isChecked} = this.state;
    const {onRegisterClick, auth: {isLoading}} = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <AuthOverlay>
        <Row>
          <Col xs="12" className="d-flex align-items-center justify-content-center">
            <Card className="auth-box card_login card_register">
              <CardBody className="px-0 py-0">
              <HeaderLogo />
                <h4 className="auth-header">
                  <Translate value="register_title"/>
                </h4>
                <div className="auth-sub-header">
              <span className="pre-link">
                      <Translate value="alreadyHaveAnAccount_question"/>
                    </span>
                  <Link to="/login">
                    <Translate value="alreadyHaveAnAccount_link"/>
                  </Link>
                </div>
                <Formik
                  initialValues={{
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    passwordConfirm: '',
                  }}
                  validationSchema={formSchema}
                  onSubmit={onRegisterClick}
                >
                  {({values, errors,handleBlur, touched, handleChange, handleSubmit, dirty, isValid}) => (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <Row form>
                          <Col md={6}>
                            <Label for="first_name" className="form-label">
                              <Translate value="first_name_label"/>
                            </Label>
                            <Input
                              className="form-control"
                              name="first_name"
                              id="first_name"
                              placeholder={I18n.t('first_name_placeholder')}
                              onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.first_name}
                              invalid={!!(errors.first_name && touched.first_name)}
                            />
                            <FormFeedback className="position-absolute">
                              {errors.first_name && touched.first_name && errors.first_name}
                            </FormFeedback>
                          </Col>
                          <Col md={6}>
                            <Label for="last_name" className="form-label">
                              <Translate value="last_name_label"/>
                            </Label>
                            <Input
                              className="form-control"
                              name="last_name"
                              id="last_name"
                              placeholder={I18n.t('last_name_placeholder')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              value={values.last_name}
                              invalid={!!(errors.last_name && touched.last_name)}
                            />
                            <FormFeedback className="position-absolute">
                              {errors.last_name && touched.last_name && errors.last_name}
                            </FormFeedback>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Label for="email" className="form-label">
                          <Translate value="email_label"/>
                        </Label>
                        <Input
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder={I18n.t('email_placeholder')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                          value={values.email}
                          invalid={!!(errors.email && touched.email)}
                        />
                        <FormFeedback className="position-absolute">
                          {errors.email && touched.email && errors.email}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Row form>
                          <Col md={6}>
                            <FormGroup className="mb-0">
                              <Label for="password" className="form-label">
                                <Translate value="password_label"/>
                              </Label>
                              <Input
                                type="password"
                                className="form-control"
                                name="password"
                                id="password"
                                placeholder={I18n.t('password_placeholder')}
                                onChange={handleChange}
                                value={values.password}
                                onBlur={handleBlur}
                                invalid={!!(errors.password && touched.password)}
                              />
                              <FormFeedback className="position-absolute">
                                {errors.password && touched.password && errors.password}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-0">
                              <Label for="passwordConfirm" className="form-label">
                                <Translate value="confirmPassword_label"/>
                              </Label>
                              <Input
                                type="password"
                                className="form-control"
                                name="passwordConfirm"
                                id="passwordConfirm"
                                placeholder={I18n.t('password_confirm_placeholder')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.passwordConfirm}
                                invalid={!!(errors.passwordConfirm && touched.passwordConfirm)}
                              />
                              <FormFeedback className="position-absolute">
                                {errors.passwordConfirm && touched.passwordConfirm && errors.passwordConfirm}
                              </FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                      <div className="auth-buttons">
                        <Row>
                          <Col
                            xs="12"
                          >
                            <CustomInput
                              type="checkbox"
                              name="remember"
                              id="remember"
                              checked={isChecked}
                              onChange={this.handleToggleCheckbox}
                              label={I18n.t('rememberMe_label')}
                              inline
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            <LoadingButton isLoading={isLoading} type="submit" color="orange" size="xl"
                                           className="mb-0 btn-md" disabled={!dirty || !isValid}>
                              <Translate value="register_btn"/>
                            </LoadingButton>
                          </Col>
                        </Row>
                        <div className="card-terms">
                          <Translate value="register_agreement"/>
                          <Link to="/terms">
                            <Translate value="terms_service_link"/>
                          </Link>
                        </div>
                        <Row hidden={true}>
                          <Col xs="12">
                            <FacebookLogin
                              appId="1088597931155576"
                              autoLoad={false}
                              fields="name,email,picture"
                              callback={this.responseFacebook}
                              cssClass="my-facebook-button-class"
                              icon="fa-facebook"
                            />
                          </Col>
                        </Row>
                      </div>

                    </Form>
                  )}
                </Formik>
              </CardBody>
              
            </Card>
          </Col>
        </Row>
      </AuthOverlay>
    )
  }
}

Register.propTypes = {
  onRegisterClick: PropTypes.func.isRequired,
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Register;
